<script>
import ApiService from "../../Utils/axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AmCharts from "../Charts/AmCharts.vue";

export default {
  components: { AmCharts },
  mounted() {
    document.title = "Modifier un guide - Auditor";
    this.fetchGuide();
  },

  data() {
    return {
      apiurl: process.env.VUE_APP_API,
      guide: null,
      done: false,
      editor: ClassicEditor, //DecoupledEditor
      editorConfig: {
        toolbar: {
          items: [
            "imageupload",
            "ckfinder",
            "|",
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "|",
            "bold",
            "italic",
            "blockQuote",
            "link",
            "alignment",
            "numberedList",
            "bulletedList",
            "insertTable",
            "mergeTableCells",
            "undo",
            "redo",
            "|",
            "code",
          ],

          shouldNotGroupWhenFull: true,
        },
      },
    };
  },
  methods: {
    fetchGuide() {
      new ApiService()
        .get("/api/guide/" + this.$route.params.id)
        .then((res) => {
          this.guide = res.data.data;
          console.log(this.guide);
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      formData.append("summary", this.guide.summary);
      // const body = {
      //   reference: this.guide.reference,
      //   title: this.guide.title,
      //   summary: this.guide.summary,
      //   status: this.guide.status,
      //   gcategory_id: this.guide.gcategory_id,
      // };

      new ApiService()
        .post("/api/guide/" + this.$route.params.id + "/update", formData)
        .then((res) => {
          console.log(res);
          // this.$router.push($i18n.locale+ "/guide");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Guide modifié avec succée",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/" + this.$i18n.locale + "/guide");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <div>
    <b-container v-if="done" fluid>
      <iq-card>
        <div class="iq-card-header d-flex justify-content-between">
          <div class="iq-header-title">
            <h4 class="card-title">Modifier un guide</h4>
          </div>
        </div>
        <form @submit="handleSubmit" class="iq-card-body">
          <div class="form-group row">
            <div class="col-lg-6">
              <label>Référence</label>
              <input
                type="text"
                class="form-control"
                v-model="guide.reference"
                name="reference"
                required
              />
            </div>

            <div class="col-lg-6">
              <label>Intitulé</label>
              <input
                type="text"
                name="title"
                class="form-control"
                v-model="guide.title"
                required
              />
            </div>

            <div class="col-lg-6 mt-2">
              <label>Statut</label>
              <select v-model="guide.status" name="status" class="form-control">
                <option value="created">Créé</option>
                <option value="validated">Validé</option>
                <option value="archived">Archivé</option>
              </select>
            </div>

            <div class="col-lg-6 mt-2">
              <label>Catégorie</label>
              <select
                v-model="guide.gcategory_id"
                name="gcategory_id"
                class="form-control"
              >
                <option value="1">Management</option>
                <option value="2">Médical</option>
              </select>
            </div>

            <div class="col-lg-6 mt-2">
              <label>Guide pdf</label>
              <div v-if="guide.file" class="mb-3">
                <!-- <div class="col-6"> -->
                <a
                  class="btn btn-primary w-45"
                  :href="apiurl + '/storage/' + guide.file.path"
                  target="_blank"
                >
                  <i class="fas fa-file-pdf"></i> Afficher
                </a>
                <!-- </div> -->
              </div>
              <b-file name="guide_pdf" class="form-file" accept=".pdf" />
            </div>
          </div>

          <!-- <div class="form-group">
            <label>Description</label>
            <textarea
              class="form-control"
              placeholder="Description"
              :value="guide.summary"
              required
              name="summary"
            ></textarea>
          </div> -->

          <div class="form-group">
            <label>Description</label>
            <div class="document-editortoolbar"></div>
            <div class="document-editoreditable-container">
              <ckeditor
                :editor="editor"
                v-model="guide.summary"
                :config="editorConfig"
                @ready="onReady"
              ></ckeditor>
            </div>
          </div>

          <button
            type="button"
            class="btn iq-bg-danger"
            @click="$router.back()"
          >
            Quitter
          </button>
          <button type="submit" class="btn btn-primary mx-2">
            Enregistrer
          </button>
        </form>
      </iq-card>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>
