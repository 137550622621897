<template>
  <div>
    <div class="wrapper">
      <div id="content-page" class="content-page ml-0 p-3">
        <b-container fluid>
          <b-row>
            <b-col lg="12">
              <iq-card
                class-name="iq-card-block iq-card-stretch iq-card-height"
              >
                <template v-slot:headerTitle>
                  <!-- <h4 class=" text-center card-title">Hospital Survey</h4> -->
                </template>
                <template v-slot:body>
                  <div class="iq-card-body">
                    <div class="row justify-content-center">
                      <img
                        v-if="user.institution.logo"
                        style="width: 15rem !important"
                        :src="apiUrl + '/storage/' + user.institution.logo.path"
                      />
                      <h1 v-else class="text-center">
                        {{ user.institution.name }}
                      </h1>
                    </div>

                    <b-row>
                      <b-col class="col-md-12">
                        <img
                          style="width: 35rem !important; height: auto"
                          v-if="user.institution.background"
                          class="rounded-lg mx-auto d-block img-fluid mt-5"
                          :src="
                            apiUrl +
                            '/storage/' +
                            user.institution.background.path
                          "
                        />
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="col-md-6 col-sm-12 offset-md-3">
                        <b-button
                          @click="redirect()"
                          variant="primary"
                          class="mx-auto d-block mt-5"
                        >
                          {{ $t("buttons.greeting") }}
                        </b-button>
                      </b-col>
                    </b-row>

                    <!-- </div> -->
                  </div>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { xray } from "../../config/pluginInit";
import IqCard from "../../components/xray/cards/iq-card";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Homepage",
  computed: {
    ...mapGetters({
      user: "Auth/getCurrentUser",
    }),
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API,
    };
  },
  mounted() {
    document.title = "Accueil - Auditor";
  },
  methods: {
    redirect() {
      // if (this.user.roles.includes("MANAGER")) {
      this.$router.push("/" + this.$i18n.locale + "/survey");
      // } else {
      //   this.$router.push($i18n.locale+ "/survey");
      // }
    },
  },
};
</script>

<style>
.iq-card-body {
  flex: unset;
}
</style>
