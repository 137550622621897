<script>
import ApiService from "../../Utils/axios";
export default {
  mounted() {
    document.title = "Modifier une grille d'evaluation - Auditor";
  },
  components: {},
  data: () => ({
    guides: [],
    guideId: null,
    chapters: [],
    chapterId: null,
    chapterTitle: null,
    crit: [],
    critId: null,
    selectedCrits: [],
  }),
  beforeMount() {
    this.fetchGuides();
  },
  methods: {
    chooseChapter(chapter) {
      this.chapterId = chapter.id;
      this.chapterTitle = chapter.title;
      this.fetchCrit();
    },
    chooseCrit(crit) {
      this.critId = crit;
      console.log(this.critId);
    },
    addCrit() {
      if (this.selectedCrits.find((e) => e.id == this.critId.id)) {
        this.$bvModal.show("modal-1");
        return;
      }
      this.selectedCrits = [
        ...this.selectedCrits,
        {
          id: this.critId.id,
          position: 55,
          question: this.critId.question,
          refernce: this.critId.refernce,
          chapter: {
            id: this.chapterId,
            title: this.chapterTitle,
            guide_title: this.guides.filter((e) => this.guideId == e.id)[0]
              .title,
            guide_id: this.guideId,
          },
        },
      ];
    },
    removeCrit(id) {
      this.selectedCrits = this.selectedCrits.filter((item) => item.id !== id);
    },
    fetchCrit() {
      new ApiService()
        .get(`/api/chapter/${this.chapterId}/list`)
        .then((res) => {
          this.crit = res.data.data.crteria;
          console.log(this.crit);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchChapters() {
      new ApiService()
        .get(`/api/guide/${this.guideId}/list`)
        .then((res) => {
          this.chapters = res.data.data.chapters;
          console.log(this.chapters);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchGuides() {
      new ApiService()
        .get("/api/guide")
        .then((res) => {
          this.guides = res.data.data.sort((a, b) => a.reference - b.reference);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      let body = {
        reference: e.target[0].value,
        title: e.target[1].value,
        description: e.target[2].value,
        criteria_id: this.selectedCrits.map((e, i) =>
          // e.id
          {
            return [e.id, { position: i + 1 }]; //
          }
        ),
      };
      console.log(body);
      const { id } = this.$route.params;
      new ApiService()
        .put("/api/grid/" + id, body)
        .then((res) => {
          this.$router.push("/grid");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Grille ajoutée avec succée",
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/grid");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Ajouter une grille d'evaluation</h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Référence</label>
            <input
              type="text"
              class="form-control"
              placeholder="Référence"
              required
            />
          </div>
          <div class="col-lg-6">
            <label>Intitulé</label>
            <input
              type="text"
              class="form-control"
              placeholder="Intitulé"
              required
            />
          </div>
        </div>

        <div class="form-group">
          <label>Description</label>
          <textarea
            class="form-control"
            placeholder="Description"
            required
          ></textarea>
        </div>

        <div class="my-5">
          <label>Ajouter critéres</label>
          <div class="row border p-2">
            <div class="form-group col-lg-3">
              <label>Guides</label>
              <b-form-select @change="fetchChapters" v-model="guideId">
                <b-form-select-option default :value="null"
                  >--- Choisir guide ---</b-form-select-option
                >
                <b-form-select-option
                  v-for="e of guides"
                  :key="e.id"
                  :value="e.id"
                  >{{ e.title }}</b-form-select-option
                >
              </b-form-select>
            </div>

            <div v-if="guideId" class="form-group col-lg-4">
              <label>Chapitres / sections</label>
              <div class="autofill">
                <ul>
                  <li v-for="e of chapters" :key="e.id">
                    <span
                      @click="chooseChapter(e)"
                      style="border-bottom: 1px solid gray"
                      :class="
                        chapterId === e.id
                          ? 'autofill-item chosen'
                          : 'autofill-item'
                      "
                      v-if="!e.section.length"
                      >{{ e.reference + " - " + e.title }}</span
                    >
                    <ul v-else>
                      <li
                        @click="chooseChapter(s)"
                        style="border-bottom: 1px solid gray"
                        :class="
                          chapterId === s.id
                            ? 'autofill-item chosen'
                            : 'autofill-item'
                        "
                        v-for="s of e.section"
                        :key="s.id"
                      >
                        {{ s.refernce + " - " + s.title }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="chapterId" class="form-group col-lg-5">
              <label>Critéres / sous critéres</label>
              <div class="autofill">
                <ul>
                  <li
                    v-for="e of crit"
                    :key="e.id"
                    style="border-bottom: 1px solid gray"
                  >
                    <span
                      @click="chooseCrit(e)"
                      :class="
                        critId && critId.id === e.id
                          ? 'autofill-item chosen'
                          : 'autofill-item'
                      "
                      v-if="!e.subcriteria.length"
                      >{{ e.refernce + " - " + e.question }}</span
                    >
                    <ul v-else>
                      <li
                        style="border-bottom: 1px solid gray"
                        :class="
                          critId && critId.id === s.id
                            ? 'autofill-item chosen'
                            : 'autofill-item'
                        "
                        v-for="s of e.subcriteria"
                        :key="s.id"
                        @click="chooseCrit(s)"
                      >
                        <span> {{ s.refernce + " - " + s.question }}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <b-button
                type="button"
                variant="primary"
                v-if="critId"
                @click="addCrit()"
                class="pull-right mt-3"
                ><i class="fas fa-plus" />Ajouter</b-button
              >
            </div>
          </div>
        </div>

        <label>Critères</label>
        <table
          v-if="selectedCrits.length"
          class="table table-hover table-bordered mb-5"
        >
          <thead>
            <tr>
              <th style="width: 8%">Réference</th>
              <th style="width: 40%">Critère</th>
              <th>Guide</th>
              <th>Chapitre</th>
              <th style="width: 8%">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="crit of selectedCrits" :key="crit.id">
              <td>{{ crit.refernce }}</td>
              <td>{{ crit.question }}</td>
              <td>{{ crit.chapter.guide_title }}</td>
              <td>{{ crit.chapter.title }}</td>
              <td class="text-center">
                <b-button
                  @click="removeCrit(crit.id)"
                  variant=" iq-bg-danger mt-1"
                  size="sm"
                  ><i class="ri-delete-bin-line m-0"></i
                ></b-button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="p-3 border text-center mb-5" v-else>
          <h3>Aucun critére relié avec cet article</h3>
        </div>

        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>
