const state = {
  answers: [],
  files: {}
};

const mutations = {
  SET_ANSWERS(state, answers) {
    state.answers = answers;
  },
  SET_FILES(state, files) {
    state.files = files;
  }
};

const getters = {
  getAnswers(state) {
    return state.answers;
  },
  getFiles(state) {
    return state.files;
  }
};

const actions = {
  saveFiles({ state, commit }, { files, crit_id }) {
    let result = state.files;
    result[crit_id] = files;
    commit("SET_FILES", result);
  },
  saveAnswers({ state, commit }, { crit_Id, answer, survey_id }) {
    let answers = state.answers;
    if (!answers.length) {
      answers.push({
        survey_id: survey_id,
        criteria_id: crit_Id,
        answer: answer
      });
    } else {
      let found = false;
      answers.forEach(e => {
        if (e.criteria_id === crit_Id) {
          e = {
            survey_id: survey_id,
            criteria_id: crit_Id,
            answer: answer
          };
          found = true;
          return;
        }
      });

      if (!found) {
        answers.push({
          survey_id: survey_id,
          criteria_id: crit_Id,
          answer: answer
        });
      }

      commit("SET_ANSWERS", answers);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
