<template>
  <div>
    <h1 class="mb-0">{{ $t("buttons.login") }}</h1>
    <p>{{ $t("loginDesc") }}</p>
    <sign-in1-form
      formType="jwt"
      email="admin@xray.com"
      password="admin123"
    ></sign-in1-form>
  </div>
</template>

<script>
import auth0 from "auth0-js";
import SignIn1Form from "./Forms/SignIn1Form";
import constant from "../../../config/constant";

export default {
  name: "SignIn1",
  components: { SignIn1Form },
  mounted() {},
  methods: {},
};
</script>
