<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">{{ tableTitle }}</h4>
          </template>
          <template v-slot:headerAction>
            <div class="row" style="padding: 10px">
              <b-form-input
                id="filter-input"
                class="col m-2"
                style="width: 300px; height: 35px"
                v-model="filter"
                type="search"
                :placeholder="$t('inputs.search')"
              ></b-form-input>
              <b-button
                class="col m-2"
                variant="primary"
                style="white-space: nowrap"
                :to="'/' + $i18n.locale + addBtn.link"
                ><i class="fas fa-plus"></i> {{ addBtn.text }}</b-button
              >
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table
                  bordered
                  :per-page="20"
                  :current-page="currentPage"
                  :filter="filter"
                  @filtered="onFiltered"
                  hover
                  :items="rows"
                  id="my-table"
                  :fields="cols"
                  foot-clone
                >
                  <template v-slot:cell(name)="data">
                    <span>{{ data.item.name }}</span>
                    <b-button
                      class="pull-right"
                      variant="outline-primary"
                      v-b-toggle="'collapse-' + data.item.id"
                      >Voir les services <i class="ri-arrow-down-s-line"></i
                    ></b-button>
                    <b-collapse :id="'collapse-' + data.item.id" class="mt-3">
                      <b-list-group>
                        <b-list-group-item
                          v-for="(service, index) of data.item.services"
                          :key="service.id"
                          >{{ index + 1 + " - " + service.name }}
                          <div class="pull-right">
                            <b-button
                              :to="
                                '/' +
                                $i18n.locale +
                                '/institution/service/edit?service_id=' +
                                service.id +
                                '&institution_id=' +
                                data.item.id
                              "
                              variant=" iq-bg-success mr-1 mt-1"
                              size="sm"
                              ><i class="ri-ball-pen-fill m-0"></i
                            ></b-button>
                            <b-button
                              @click="onRemoveS(service)"
                              variant=" iq-bg-danger mr-1 mt-1"
                              size="sm"
                              ><i class="ri-delete-bin-line m-0"></i
                            ></b-button></div
                        ></b-list-group-item>
                      </b-list-group>
                      <b-button
                        class="mt-2"
                        :to="
                          '/' +
                          $i18n.locale +
                          '/institution/service/create?institution_id=' +
                          data.item.id
                        "
                        variant="primary"
                      >
                        <i class="fas fa-plus"></i
                        >{{ $t("buttons.add") }}</b-button
                      >
                    </b-collapse>
                  </template>
                  <template v-slot:cell(category)="data">
                    <span>{{ data.item.category.title }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-success mr-1 mt-1"
                      size="sm"
                      @click="edit(data.item.id)"
                      v-if="cols[0].editable"
                      ><i class="ri-ball-pen-fill m-0"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-danger mr-1 mt-1"
                      size="sm"
                      v-if="cols[0].deleteable"
                      @click="onRemove(data.item)"
                      ><i class="ri-delete-bin-line m-0"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3 pull-right"
                  v-model="currentPage"
                  :total-rows="rows.length"
                  :per-page="20"
                  aria-controls="my-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-1" title="Attention!" @ok="remove(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer l'établissement
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal>

    <b-modal id="modal-2" title="Attention!" @ok="removeS(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer le service
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from "../../config/pluginInit";
import ApiService from "../../Utils/axios";

export default {
  name: "UiDataTable",
  props: {
    addBtn: Object,
    tableTitle: String,
    rows: Array,
    cols: Array,
    fetchList: Function,
  },
  mounted() {
    xray.index();
  },
  methods: {
    default() {
      return {
        id: this.rows.length,
        name: "",
        position: "",
        office: "",
        age: "",
        start_date: "2011/04/25",
        salary: "$0",
        editable: false,
      };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRemoveS(item) {
      this.item = item;
      this.modalMsg = item.name;
      this.$bvModal.show("modal-2");
    },
    removeS(item) {
      new ApiService()
        .delete("/api/service/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Service supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "Le service ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
    edit(id) {
      this.$router.push("/" + this.$i18n.locale + "/institution/edit/" + id);
    },
    submit(item) {
      item.editable = false;
    },
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.name;
      this.$bvModal.show("modal-1");
    },
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/institution/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Etablissement supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "L'établissement ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
  },
  data() {
    return {
      currentPage: 1,
      filter: "",
      modalMsg: "",
      item: null,
    };
  },
};
</script>
