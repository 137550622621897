import Vue from "vue";
import "@babel/polyfill";
import "mutationobserver-shim";
import "./Utils/fliter";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Raphael from "raphael/raphael";
import "./plugins";
import "./registerServiceWorker";
import i18n from "./i18n";
import vSelect from "vue-select";
import Nl2br from "vue-nl2br";
import Slider from "vue-custom-range-slider";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import "vue-custom-range-slider/dist/vue-custom-range-slider.css";
import VueHtmlToPaper from 'vue-html-to-paper';
import excel from "vue-excel-export";
// import Styles from './assets/css/rtl/bootstrap.scss';

// Vue.use(CKEditor);

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css',
    'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css',
    '/style.css'
    // 'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.component("Slider", Slider);
Vue.component("v-select", vSelect);
Vue.component("nl2br", Nl2br);

Vue.use(CKEditor);//
Vue.use(VueHtmlToPaper, options);
Vue.use(excel)

global.Raphael = Raphael;
Vue.config.productionTip = false;

let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");

window.vm = vm;
