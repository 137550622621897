<template>
  <!-- loader Start -->
  <div id="loading" :style="style">
    <div id="loading-center">
      <h1
        style="font-size: 70px; font-weight:600;margin-top:23%"
        class="text-primary text-center"
      >
        Auditor
      </h1>
    </div>
  </div>
  <!-- loader END -->
</template>
<script>
import lightLogo from "../../../assets/images/loader.gif";
import darkLogo from "../../../assets/images/darkMode/dark-loader.gif";
export default {
  name: "Loader",
  props: {
    dark: { type: Boolean, default: false }
  },
  mounted() {
    if (this.$route.meta.dark) {
      this.style = `background: #1d203f no-repeat scroll center center;`;
    } else {
      this.style = `background: #fff no-repeat scroll center center;`;
    }
  },
  data() {
    return {
      logo: lightLogo,
      darkLogo: darkLogo,
      style: `background: #fff url(${lightLogo}) no-repeat scroll center center;`
    };
  }
};
</script>
