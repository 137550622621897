<script>
import ApiService from "../../Utils/axios";
export default {
  name: "guide-import",
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let form = new FormData(e.currentTarget);

      new ApiService()
        .post("/api/guide/import", form)
        .then((res) => {
          console.log(res);
          this.$router.push("/" + this.$i18n.locale + "/guide");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Guide importé avec succée",
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title text-primary">
                {{ $t("pageTitles.import") }}
              </h4>
            </div>
          </div>
          <div class="iq-card-body">
            <h5>Selectionner un guide à importer</h5>
            <form @submit="handleSubmit">
              <div class="form-group row">
                <div class="col-lg-6">
                  <label>Fichier</label>
                  <b-form-file placeholder="Classeur Guide" name="xlsfile" />
                  <label for="">Nombre de lignes du tableau</label>
                  <b-form-input placeholder="ex:128" name="linesNumber" />
                </div>
              </div>
              <button type="submit" class="btn btn-primary mx-2">
                Enregistrer
              </button>
            </form>
          </div>
        </iq-card>
      </b-col>
    </b-row>

    <!-- <b-modal id="modal-1" title="Attention!" @ok="remove(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer l'établissement
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal> -->
  </b-container>
</template>
