<script>
import ApiService from "../../Utils/axios";
export default {
  mounted() {
    document.title = "Modifier une fonction - Auditor";
  },
  data() {
    return { pos: null };
  },
  beforeMount() {
    this.fetchPos();
  },
  methods: {
    fetchPos() {
      const { id } = this.$route.params;
      new ApiService()
        .get("/api/position/" + id)
        .then((res) => {
          this.pos = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      let body = {
        reference: e.target[0].value,
        title: e.target[1].value,
        description: e.target[2].value,
      };
      const { id } = this.$route.params;
      new ApiService()
        .put("/api/position/" + id, body)
        .then((res) => {
          this.$router.push("/" + this.$i18n.locale + "/function");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Fonction modifié avec succée",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/" + this.$i18n.locale + "/function");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Modifier une fonction</h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Référence</label>
            <input
              v-model="pos.reference"
              type="text"
              class="form-control"
              placeholder="Référence"
              required
            />
          </div>
          <div class="col-lg-6">
            <label>Intitulé</label>
            <input
              v-model="pos.title"
              type="text"
              class="form-control"
              placeholder="Intitulé"
              required
            />
          </div>
        </div>

        <div class="form-group">
          <label>Description</label>
          <textarea
            v-model="pos.description"
            class="form-control"
            placeholder="Description"
            required
          ></textarea>
        </div>

        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>
