<template>
  <div>
    <Alert />
    <h1 class="mb-0">Reset Mot de passe</h1>
    <p>
      Entrez votre adresse e-mail et nous vous enverrons un e-mail avec des
      instructions pour réinitialisez votre mot de passe.
    </p>
    <form class="mt-4" @submit="handleSubmit">
      <!-- @submit.prevent="requestResetPassword" method="post"-->
      <div class="form-group">
        <label for="exampleInputEmail1">adresse Email</label>
        <input
          type="email"
          class="form-control mb-0"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="nom@exemple.com"
          v-model="email"
        />
      </div>

      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">
          Envoyer
        </button>
      </div>
    </form>

    <router-link :to="'/' + $i18n.locale + '/auth/login'"
      ><i class="fas fa-chevron-left" /> Retour au login</router-link
    >
  </div>
</template>
<script>
import ApiService from "../../../Utils/axios";
import axios from "axios";
import Alert from "../../../components/xray/alerts/Alert";
export default {
  name: "RecoverPassword1",
  props: ["formType"],
  components: [Alert],

  data() {
    return {
      email: null,
      has_error: false,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      console.log(this.email);
      // let body = {
      //   email: this.email
      // };
      axios
        .put(process.env.VUE_APP_API + "/api/reset-password", {
          email: this.email,
        })
        .then((res) => {
          this.response = res.data;
          console.log(res.data);
          // this.$router.push($i18n.locale+ "/auth/password-reset");
          if (res.data.status == "error") {
            this.$store.dispatch("Alert/activate", {
              type: "danger",
              text: res.data.message,
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              type: "success",
              text: "Un lien vous permettant de réinitialiser votre mot de passe vous a été envoyé par mail.",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          // this.$router.push($i18n.locale+ "/auth/password-reset");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>
