<template>
  <b-container fluid>
    <b-row class="justify-content-center">
      <b-col lg="12">
        <ApexChart :element="type" :chartOption="bodyData" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from "../../config/pluginInit";

export default {
  name: "ApexCharts",
  mounted() {
    xray.index();
    console.log(this.bodyData);
  },
  props: { bodyData:Object },
  methods: {
    generateData(baseval, count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;
        var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

        series.push([baseval, y, z]);
        baseval += 86400000;
        i++;
      }
      return series;
    }
  },
  data() {
    return {
      title: "Pie Charts",
      type: "pie"
    };
  }
};
</script>
