<template>
  <div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link to="/">
        <span>Auditor</span>
      </router-link>
      <div class="iq-menu-bt-sidebar" v-if="toggleButton">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-more-fill"></i></div>
            <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <List :items="menuItems" :open="true" :horizontal="horizontal" />
      </nav>
    </div>
    <!-- <b-dropdown
      style="position: absolute; bottom: 10px"
      variant="outline-warning"
      class="px-3"
    >
      <template #button-content>
        <img
          style="width: 25px"
          :src="require('../../../assets/images/' + $i18n.locale + '.png')"
        />
        {{ $i18n.locale.toUpperCase() }}
      </template>
      <b-dropdown-item
        :active="$i18n.locale == 'fr'"
        @click="$i18n.locale = 'fr'"
      >
        <img
          style="width: 25px"
          :src="require('../../../assets/images/fr.png')"
        />
        FR</b-dropdown-item
      >
      <b-dropdown-item
        :active="$i18n.locale == 'en'"
        @click="$i18n.locale = 'en'"
      >
        <img
          style="width: 25px"
          :src="require('../../../assets/images/en.png')"
        />
        EN</b-dropdown-item
      >
    </b-dropdown> -->
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import List from "../menus/ListStyle1";
export default {
  name: "SideBarStyle1",
  props: {
    homeURL: { type: Object, default: () => ({ name: "layout1.dashboard" }) },
    items: { type: Array },
    logo: { type: String, default: require("../../../assets/images/logo.png") },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true },
  },
  data() {
    return {
      menuItems: [
        // {
        //   title: "dashboard",
        //   name: "Tableau de bord",
        //   is_heading: false,
        //   is_active: true,
        //   link: "/" +this.$i18n.locale + "/dashboard",
        //   class_name: "",
        //   is_icon_class: true,
        //   role: [],
        //   icon: "fas fa-tachometer-alt"
        // },
        {
          title: "Administration",
          name: "Administration",
          is_heading: true,
          is_active: false,
          class_name: "",
          is_icon_class: false,
          role: ["MANAGE_ORGANISATIONS"],
          icon: "",
        },
        {
          title: "Organisation",
          name: "Organisation",
          is_heading: false,
          is_active: false,
          link: "",
          class_name: "",
          is_icon_class: true,
          icon: "fas fa-building",
          role: ["MANAGE_ORGANISATIONS"],
          children: [
            {
              title: "list-institution",
              name: this.$t("menus.institution"),
              is_active: false,
              link: "/" + this.$i18n.locale + "/institution",
              class_name: "",
              is_icon_class: true,
              role: ["MANAGE_ORGANISATIONS"],
              icon: "fas fa-university",
            },
            {
              title: "list-function",
              name: this.$t("menus.function"),
              is_active: false,
              link: "/" + this.$i18n.locale + "/function",
              class_name: "",
              is_icon_class: true,
              role: ["MANAGE_ORGANISATIONS"],
              icon: "fas fa-cog",
            },
            {
              title: "list-user",
              name: this.$t("menus.users"),
              is_active: false,
              link: "/" + this.$i18n.locale + "/user",
              class_name: "",
              is_icon_class: true,
              role: ["MANAGE_ORGANISATIONS"],
              icon: "fas fa-users",
            },
          ],
        },
        {
          title: "Gestion documentaire",
          name: this.$t("menus.documentManagement"),
          is_heading: true,
          is_active: false,
          class_name: "",
          is_icon_class: false,
          icon: "",
          role: ["MANAGE_GUIDES"],
        },
        {
          title: "Guides",
          name: "Guides",
          is_heading: false,
          is_active: false,
          link: "",
          class_name: "",
          is_icon_class: true,
          icon: "fas fa-book",
          role: ["MANAGE_GUIDES", "SHOW_GUIDES"],
          children: [
            {
              title: "guide-list",
              name: this.$t("menus.list"),
              is_active: false,
              link: "/" + this.$i18n.locale + "/guide",
              class_name: "",
              is_icon_class: true,
              icon: "fas fa-list-ul",
              role: ["MANAGE_GUIDES", "SHOW_GUIDES"],
            },
            {
              title: "guide-import",
              name: this.$t("menus.import"),
              is_active: false,
              link: "/" + this.$i18n.locale + "/guide/import",
              class_name: "",
              is_icon_class: true,
              icon: "fas fa-file-upload",
              role: ["MANAGE_GUIDES"],
            },
            {
              title: "grid-list",
              name: this.$t("menus.evaluationGrids"),
              is_active: false,
              link: "/" + this.$i18n.locale + "/grid",
              class_name: "",
              is_icon_class: true,
              icon: "ri-grid-fill",
              role: ["MANAGE_GUIDES"],
            },
            {
              title: "list-post",
              name: "Articles",
              is_active: false,
              link: "/" + this.$i18n.locale + "/post",
              class_name: "",
              is_icon_class: true,
              role: ["MANAGE_GUIDES"],
              icon: "fas fa-newspaper",
            },
          ],
        },
        {
          title: "Evaluations",
          name: "Evaluations",
          is_heading: true,
          is_active: false,
          class_name: "",
          is_icon_class: false,
          role: [],
          icon: "",
        },
        {
          title: "Audits",
          name: "Audits",
          is_heading: false,
          is_active: false,
          link: "",
          class_name: "",
          is_icon_class: true,
          icon: "fas fa-clipboard-list",
          role: [],
          children: [
            {
              title: "list-survey",
              name: this.$t("menus.surveys"),
              is_active: false,
              link: "/" + this.$i18n.locale + "/survey",
              class_name: "",
              is_icon_class: true,
              role: [
                "MANAGE_SURVEYS",
                "SHOW_COMPANY_SURVEYS",
                "SHOW_SERVICE_SURVEYS",
              ],
              icon: "fas fa-list-ol",
            },
            {
              title: "list-assessment",
              name: this.$t("menus.results"),
              is_active: false,
              link: "/" + this.$i18n.locale + "/assessment",
              class_name: "",
              role: [
                "MANAGE_ASSESSMENTS",
                "SHOW_COMPANY_ASSESSMENTS",
                "SHOW_SERVICE_ASSESSMENTS",
                "SHOW_HIS_ASSESSMENTS",
              ],
              is_icon_class: true,
              icon: "fas fa-medal",
            },
          ],
        },
      ],
    };
  },
  components: {
    List,
  },
  mounted() {
    const element = document.querySelectorAll(".iq-sidebar-menu li a");
    Array.from(element, (elem) => {
      elem.addEventListener("click", (e) => {
        const prevMenu = window
          .$(".iq-sidebar-menu span")
          .has(".iq-submenu")
          .find("ul.show")
          .attr("id");
        const prevMenuElem = document.getElementById(prevMenu);
        if (prevMenuElem !== null) {
          Array.from(
            prevMenuElem.closest("li").getElementsByClassName("not-collapsed"),
            (el) => {
              if (elem.closest("ul").getAttribute("id") !== prevMenu) {
                this.$root.$emit("bv::toggle::collapse", prevMenu);
                prevMenuElem.closest("li").classList.remove("active");
              }
              if (elem.getAttribute("aria-controls") === prevMenu) {
                this.$root.$emit("bv::toggle::collapse", prevMenu);
              }
            }
          );
        }
        window.$(elem).closest("li").has(".iq-submenu").addClass("active");
      });
    });
  },
  methods: {
    miniSidebar() {
      this.$emit("toggle");
    },
  },
};
</script>
