<template>
  <div>
    <div class="wrapper">
      <div id="content-page" class="content-page ml-0 p-3">
        <b-container fluid>
          <b-row>
            <b-col lg="12">
              <iq-card
                class-name="iq-card-block iq-card-stretch iq-card-height"
                style="min-height: 93vh"
              >
                <template v-slot:headerTitle>
                  <!-- <h4 class=" text-center card-title">Hospital Survey</h4> -->
                </template>
                <template v-slot:body>
                  <div class="iq-card-body text-center">
                    <b-row>
                      <b-col class="col-md-12">
                        <div style="margin-top: 10%">
                          <img
                            class="mx-auto d-block img-fluid"
                            :src="logo"
                            style="width: 50%"
                          />

                          <h3 class="mt-5">
                            {{ $t("greeting") }}
                          </h3>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="col-md-6 col-sm-12 offset-md-3">
                        <router-link
                          :to="'/' + $i18n.locale + '/auth/login'"
                          style="margin-top: 10%"
                          class="mx-auto d-block w-50 btn btn-lg btn-block btn-primary"
                        >
                          {{ $t("buttons.login") }}</router-link
                        >
                      </b-col>
                    </b-row>

                    <!-- </div> -->
                  </div>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Greeting",
  data: () => {
    return {
      logo: require("../../../assets/images/euromedi_logo.png"),
    };
  },
  mounted() {
    document.title = "Greeting - Auditor";
  },
};
</script>
