<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">{{ tableTitle }}</h4>
          </template>
          <template v-slot:headerAction>
            <div class="row" style="padding: 10px">
              <b-dropdown
                class="col mt-2"
                style="height: 35px"
                variant="primary"
                menu-class="p-2"
              >
                <template #button-content
                  ><i
                    class="fas fa-filter"
                    style="color: white; font-size: 14px"
                  />
                  {{ $t("buttons.filter") }}
                </template>
                <table class="">
                  <tr>
                    <td class="p-2">Etablissement</td>
                    <td>
                      <select
                        style="width: 150px"
                        class="form-control-sm pull-right"
                        @change="
                          (e) =>
                            handleFilter(
                              e.target.value,
                              'e.institution.id',
                              'inst'
                            )
                        "
                      >
                        <option value="all">Tout</option>
                        <option
                          v-for="inst of institutions"
                          :key="inst.id"
                          :value="inst.id"
                        >
                          {{ inst.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-2">Service</td>
                    <td>
                      <select
                        @change="
                          (e) => handleFilter(e.target.value, 'serv', 'serv')
                        "
                        style="width: 150px"
                        class="form-control-sm pull-right"
                      >
                        <option value="all">Tout</option>
                        <option
                          v-for="serv of services"
                          :key="serv.id"
                          :value="serv.id"
                        >
                          {{ serv.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-2">Niveau</td>
                    <td>
                      <select
                        @change="
                          (e) =>
                            handleFilter(e.target.value, 'e.level', 'level')
                        "
                        style="width: 150px"
                        class="form-control-sm pull-right"
                      >
                        <option value="all">Tout</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </td>
                  </tr>
                </table>
                <button
                  @click="filteredList = null"
                  class="btn btn-danger btn-sm pull-right mt-1"
                >
                  Reset
                </button>
              </b-dropdown>
              <b-form-input
                id="filter-input"
                class="col m-2"
                style="width: 300px; height: 35px"
                v-model="filter"
                type="search"
                :placeholder="$t('inputs.search')"
              ></b-form-input>
              <b-button
                class="col m-2"
                variant="primary"
                style="white-space: nowrap"
                :to="'/' + $i18n.locale + addBtn.link"
                v-if="user.roles.includes('ADMIN')"
                ><i class="fas fa-plus"></i> {{ addBtn.text }}</b-button
              >
            </div>
          </template>
          <template v-slot:body>
            <b-row class="justify-content-end">
              <!-- <b-dropdown
                toggle-class="text-decoration-none"
                :no-caret="true"
                class="mx-3 mb-3"
                variant="link"
              >
                <template #button-content>
                  <i class="fas fa-bars" /> Ordre
                </template>
                <b-dropdown-item>par niveau</b-dropdown-item>
                <b-dropdown-item>par extablissement</b-dropdown-item>
                <b-dropdown-item>par service</b-dropdown-item>
              </b-dropdown> -->
              <b-col md="12" class="table-responsive">
                <b-table
                  :per-page="20"
                  :current-page="currentPage"
                  bordered
                  :filter="filter"
                  @filtered="onFiltered"
                  hover
                  :items="filteredList ? filteredList : rows"
                  id="my-table"
                  :fields="cols"
                  foot-clone
                >
                  <template v-slot:cell(institution)="data">
                    <span>{{ data.item.institution.name }}</span>
                  </template>
                  <template v-slot:cell(services)="data">
                    <li v-for="ser of data.item.services" :key="ser.id">
                      {{ ser.name }}
                    </li>
                  </template>
                  <template v-slot:cell(level)="data">
                    <div class="text-center mt-3">
                      <span v-if="data.item.level == 4">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />

                        <i class="fas fa-crown star-gold star" />
                      </span>
                      <span v-if="data.item.level == 3">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />

                        <i class="fas fa-crown star" />
                      </span>
                      <span v-if="data.item.level == 2">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star" />

                        <i class="fas fa-crown star" />
                      </span>
                      <span v-if="data.item.level == 1">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star" />
                        <i class="fas fa-star star" />

                        <i class="fas fa-crown star" />
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-success mr-1 mt-1"
                      size="sm"
                      v-if="user.roles.includes('ADMIN')"
                      @click="edit(data.item.id)"
                      ><i class="ri-ball-pen-fill m-0"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-danger mr-1 mt-1"
                      size="sm"
                      v-if="user.roles.includes('ADMIN')"
                      @click="onRemove(data.item)"
                      ><i class="ri-delete-bin-line m-0"></i
                    ></b-button>
                    <!-- TODO: Afficher le bouton démarrer si le service de l'utilisateur se trouve dans la liste des services du questionnaire -->
                    <b-button
                      variant=" iq-bg-primary mr-1 mt-1"
                      size="sm"
                      title="Demarrer le questionnaire"
                      v-if="checkServices(user.service, data.item.services)"
                      @click="assessmentExist(data.item.id)"
                      ><i class="ri-play-line m-0"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3 pull-right"
                  v-model="currentPage"
                  :total-rows="filteredList ? filteredList.length : rows.length"
                  :per-page="20"
                  aria-controls="my-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-1" title="Attention!" @ok="remove(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer le questionnaire
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import { xray } from "../../config/pluginInit";
import ApiService from "../../Utils/axios";

// import starGray from "../icons/star-gray.svg";
// import laurelGray from "../icons/laurel-gray.svg";
// import laurel from "../icons/laurel.svg";
// import star from "../icons/star.svg";
export default {
  name: "UiDataTable",
  props: {
    addBtn: Object,
    tableTitle: String,
    rows: Array,
    cols: Array,
    fetchList: Function,
  },
  data: () => ({
    currentPage: 1,
    filter: "",
    modalMsg: "",
    item: null,
    institutions: [],
    services: [],
    filteredList: null,
    filters: {},
  }),
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  beforeMount() {
    this.fetchInstitutions();
    this.fetchServices();
  },
  mounted() {
    xray.index();
    console.log(this.rows);
    // console.log(this.rows);
  },
  methods: {
    fetchInstitutions() {
      new ApiService()
        .get("/api/institution")
        .then((res) => {
          console.log(res);
          this.institutions = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchServices() {
      new ApiService()
        .get("/api/service")
        .then((res) => {
          this.services = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    default() {
      return {
        id: this.rows.length,
      };
    },
    checkServices(userS, itemS) {
      let res = false;
      itemS.forEach((e) => {
        if (e.id === userS.id) {
          res = true;
          return;
        }
      });
      return res;
    },
    handleFilter(v, target, name) {
      this.filters[name] = v;
      let list = this.rows;
      if (v == "all") {
        this.filters[name] = null;
        console.log(this.filters);
        list = this.rows;
        const { inst, level, serv } = this.filters;
        if (serv) {
          let arr = [];
          list.forEach((e) => {
            e.services.forEach((ser) => {
              if (ser.id == serv) arr.push(e);
            });
          });
          list = arr;
        }
        if (inst) list = list.filter((e) => e.id == inst);
        if (level) list = list.filter((e) => e.level == level);
        this.filteredList = list;
        return;
      }
      if (target == "serv") {
        let arr = [];
        list.forEach((e) => {
          e.services.forEach((ser) => {
            if (ser.id == v) arr.push(e);
          });
        });

        return (this.filteredList = arr);
      }

      this.filteredList = list.filter((e) => eval(target) == v);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    edit(id) {
      this.$router.push("/" + this.$i18n.locale + "/survey/edit/" + id);
    },
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.title;
      this.$bvModal.show("modal-1");
    },
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/survey/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Questionnaire supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "Le questionnaire ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
    async assessmentExist(survey_id) {
      const api = new ApiService();
      const exist = await api.get(`/api/survey/${survey_id}/exist`);
      if (exist.data.assessment_id) {
        this.$router.push(
          "/" +
            this.$i18n.locale +
            `/assessment/start?survey_id=${survey_id}&assessment_id=${exist.data.assessment_id}`
        );
      } else {
        api
          .post(`/api/assessment`, {
            survey_id: survey_id,
          })
          .then((res) => {
            this.$router.push(
              "/" +
                this.$i18n.locale +
                `/survey/start?survey_id=${survey_id}&assessment_id=${res.data.id}`
            );
          })
          .catch((e) => console.log(e.response));
      }
    },
  },
};
</script>

<style>
.star {
  width: 18px;
}

.laurel {
  width: 27px;
}
</style>
