<script>
import AdvancedSearch from "vue-advanced-search";
import ApiService from "../../Utils/axios";
import axios from "axios";
import Multiselect from "vue-select";
export default {
  data() {
    return {
      zip: "",
      state: "",
      city: "",
      options: [],
      category: [],
      autofill: false,
      guides: [],
      selectedGuides: [],
    };
  },
  components: { AdvancedSearch, MultiS: Multiselect },
  beforeMount() {
    new ApiService()
      .get("/api/category")
      .then((res) => (this.category = res.data));
    this.fetchGuides();
  },
  mounted() {
    document.title = "Ajouter un établissement - Auditor";
  },
  methods: {
    fetchGuides() {
      new ApiService()
        .get("/api/guide")
        .then((res) => {
          this.guides = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLocationByZip() {
      axios
        .get(
          `https://app.zipcodebase.com/api/v1/search?apikey=c93f76d0-f842-11eb-9c42-b3bcf8adef54&codes=${this.zip}`
        )
        .then((res) => {
          this.options = res.data.results[this.zip];
          this.autofill = true;
        })
        .catch((err) => console.log(err));
    },
    handleSubmit(e) {
      e.preventDefault();

      let form = new FormData(e.currentTarget);
      form.append("guides", JSON.stringify(this.selectedGuides));
      new ApiService()
        .post("/api/institution", form)
        .then((res) => {
          // console.log(res);
          this.$router.push("/" + this.$i18n.locale + "/institution");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Etablissement ajouté avec succée",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/" + this.$i18n.locale + "/institution");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
    handleAutofill(option) {
      this.state = option.country_code;
      this.city = option.city;
      this.autofill = false;
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Ajouter un établissement</h4>
        </div>
      </div>
      <div class="iq-card-body">
        <form @submit="handleSubmit">
          <div class="form-group row">
            <div class="col-lg-6">
              <label>Logo</label>
              <b-file name="logo_image" accept="image/png, image/jpeg" />
            </div>
            <div class="col-lg-6">
              <label>Image de fond</label>
              <b-file name="bg_image" accept="image/png, image/jpeg" />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-6">
              <label>Nom</label>
              <input
                type="text"
                class="form-control"
                placeholder="Nom"
                required
                name="name"
              />
            </div>
            <div class="col-lg-6">
              <label>Catégorie</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                required
                name="icategory_id"
              >
                <option v-for="cat of category" :key="cat.id" :value="cat.id">
                  {{ cat.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-6">
              <label>Zip Code</label>
              <input
                name="zip"
                type="text"
                v-model="zip"
                class="form-control"
                placeholder="Zip Code"
                @input="getLocationByZip()"
                required
              />
              <div class="autofill" v-if="autofill && options.length">
                <ul>
                  <li
                    class="autofill-item"
                    v-for="option of options"
                    @click="handleAutofill(option)"
                    :key="option.city"
                  >
                    {{ option.state + ", " + option.city }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <label>Pays</label>
              <input
                type="text"
                name="country"
                class="form-control"
                placeholder="Pays"
                v-model="state"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-6">
              <label>Localité</label>
              <input
                name="city"
                type="text"
                v-model="city"
                class="form-control"
                placeholder="Localité"
                required
              />
            </div>
            <div class="col-lg-6">
              <label>Téléphone</label>
              <input
                name="phone"
                type="number"
                class="form-control"
                placeholder="Téléphone"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label>Adresse</label>
            <input
              type="text"
              class="form-control"
              placeholder="Adresse"
              required
              name="address"
            />
          </div>
          <div class="form-group">
            <label>Guides</label>
            <MultiS
              :options="guides"
              :reduce="(guide) => guide.id"
              v-model="selectedGuides"
              label="title"
              class="style-chooser"
              multiple
            />
          </div>

          <button
            type="button"
            class="btn iq-bg-danger"
            @click="$router.back()"
          >
            Annuler
          </button>
          <button type="submit" class="btn btn-primary mx-2">
            Enregistrer
          </button>
        </form>
      </div>
    </iq-card>
  </b-container>
</template>

<style scoped>
.autofill {
  height: 300px;
  position: fixed;
  background: white;
  z-index: 10;
  overflow-y: scroll;
  border-radius: 10px;
  border: 1px solid #d7dbda;
  max-width: 500px;
}
.autofill-item {
  cursor: pointer;
}
.autofill-item:hover {
  background: #d9dddc88;
}
ul {
  padding-inline-start: 10px !important;
}
</style>
