<script>
import ApiService from "../../Utils/axios";
import * as am4core from "@amcharts/amcharts4/core";
import iqCard from "../../components/xray/cards/iq-card.vue";
import { mapGetters } from "vuex";
export default {
  components: { iqCard },
  data: () => ({
    inst: [],
    done: false,
    selectedInst: 0,
    chart: {
      title: "Column Chart",
      type: "column",
      bodyData: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#0084ff", "#00ca00", "#ffc107"],
        series: [
          {
            name: "Taux de participation",
            data: [44, 55, 57, 56, 61, 58],
          },
          {
            name: "Nombre d'utilisateurs",
            data: [76, 85, 101, 98, 87, 105],
          },
        ],
        xaxis: {
          categories: [
            "Chirurgie du jour",
            "Management",
            "Infirmerie",
            "Maternité",
            "Consultations",
            "Pediaterie",
          ],
        },
        yaxis: {
          title: {
            text: "Nb utilisateurs",
          },
        },
        fill: {
          opacity: 1,
        },
      },
    },
  }),
  computed: {
    ...mapGetters({
      user: "Auth/getCurrentUser",
    }),
  },
  beforeMount() {
    document.title = "Dashboard - Auditor";
  },
  methods: {},
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="pt-1">
            Etablissement :
            <span class="text-primary">{{ user.institution.name }}</span>
          </h4>
        </div>
      </div>
      <div class="iq-card-body">
        <!-- <b-card
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          align="center"
          :header="user.service.name"
          :id="user.service.id"
          style="border: 1px solid"
        >
          <AmChart
            :element="'home-chart-' + user.service.id"
            type="radial-pie"
            :option="[
              {
                country: 'Réponses',
                value: Math.random() * (100 - 10) + 10
              },
              {
                country: this.$t('noAnswer'),
                value: Math.random() * (100 - 10) + 10
              },
              {
                country: 'Total',
                value: Math.random() * (100 - 10) + 10
              }
            ]"
            :height="280"
          />
        </b-card> -->

        <b-card
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          align="center"
          header="Gestion des résidentss"
          id="1"
          style="border: 1px solid"
        >
          <AmChart
            :element="'home-chart-' + 1"
            type="radial-pie"
            :option="[
              {
                country: 'Réponse',
                value: Math.random() * (100 - 10) + 10,
              },
              {
                country: 'Sans réponse',
                value: Math.random() * (100 - 10) + 10,
              },
              {
                country: 'Total',
                value: Math.random() * (100 - 10) + 10,
              },
            ]"
            :height="280"
          />
        </b-card>
      </div>
    </iq-card>
  </b-container>
</template>

<style>
.card-header {
  font-size: large;
}

/* #content-page { */
/* background-image: url("residence_cambre.jpg"); */
/* background-repeat: no-repeat; */
/* background-size: cover; */
/* } */

/* .iq-card {
  opacity: 0.93;
} */
</style>
