import authService from "../../services/auth";

const state = {
  currentUser: null
};

const mutations = {
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  }
};

const getters = {
  // Whether the user is currently logged in.
  isLoggedIn(state) {
    return !!state.currentUser;
  },

  getCurrentUser(state) {
    return state.currentUser;
  }
};

const actions = {
  // Logs in the current user.
  async login({ commit }, { userData, remember }) {
    try {
      const { data } = await authService.login(userData);
      commit("SET_CURRENT_USER", data.user);
      saveState("remember", remember);
      saveState("token", data.token);
    } catch (e) {
      return e.response.data.message;
    }
  },

  async getSession({ commit }) {
    const token = getSavedState("token");

    try {
      const { data } = await authService.getSession(token);
      commit("SET_CURRENT_USER", data.user);
      saveState("token", data.token);
    } catch (e) {
      console.log(e.response);
      throw new Error(e);
    }
  },

  // Logs out the current user.
  async logout({ commit }) {
    const token = getSavedState("token");
    try {
      await authService.logout(token);
      commit("SET_CURRENT_USER", null);
      saveState("token", null);
    } catch (e) {
      console.log(e.response);
    }
  }
};

// ================
// Private helpers
// ================

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
