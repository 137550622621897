<script>
import ApiService from "../../Utils/axios";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import iqCard from "../../components/xray/cards/iq-card.vue";
import stdDashboard from "./StandardDashboard.vue";
import mngDashboard from "./ManagerDashboard.vue";
import { mapGetters } from "vuex";
export default {
  components: { iqCard, stdDashboard, mngDashboard },
  data: () => ({
    inst: [],
    done: false,
    selectedInst: 0,
    chart: {
      title: "Column Chart",
      type: "column",
      bodyData: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#0084ff", "#00ca00", "#ffc107"],
        series: [
          {
            name: "Taux de participation",
            data: [44, 55, 57, 56, 61, 58],
          },
          {
            name: "Nombre d'utilisateurs",
            data: [76, 85, 101, 98, 87, 105],
          },
        ],
        xaxis: {
          categories: [
            "Chirurgie du jour",
            "Management",
            "Infirmerie",
            "Maternité",
            "Consultations",
            "Pediaterie",
          ],
        },
        yaxis: {
          title: {
            text: "Nb utilisateurs",
          },
        },
        fill: {
          opacity: 1,
        },
      },
    },
  }),
  computed: {
    ...mapGetters({
      user: "Auth/getCurrentUser",
    }),
  },
  beforeMount() {
    document.title = "Dashboard - Auditor";
    if (this.user.roles.includes("ADMIN")) {
      this.fetchInst();
      // this.fetchResults();
    }
  },
  methods: {
    fetchInst() {
      this.done = false;
      new ApiService()
        .get("/api/institution")
        .then((res) => {
          this.inst = res.data.data;
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchResults() {
      new ApiService()
        .get(`/api/dashboard/institutions/${this.user.id}`)
        .then((res) => {
          // let obj = {};
          // res.data.data.survey.forEach(e => {
          //   obj[] = { answer: e.answer, files: e.files };
          // });
          // console.log(res.data);
          // this.stats = res.data.data.survey;
        })
        .catch(() => {
          this.$router.push("/" + this.$i18n.locale + "/dashboard");
          this.$store.dispatch("Alert/activate", {
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
            type: "danger",
          });
        });
    },
  },
};
</script>

<template>
  <b-container v-if="user.roles.includes('ADMIN')" fluid>
    <div v-if="done">
      <iq-card>
        <div class="iq-card-header d-flex justify-content-between">
          <div class="iq-header-title">
            <span class="d-flex">
              <h4 class="text-primary col-6 pt-1">Etablissement :</h4>
              <b-form-select class="col-6" v-model="selectedInst"
                ><option v-for="(e, i) of inst" :key="e.id" :value="i">
                  {{ e.name }}
                </option></b-form-select
              >
            </span>
          </div>
        </div>
        <div class="iq-card-body">
          <b-card-group deck>
            <!-- <b-card
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
              align="center"
              v-for="s of inst[selectedInst].services"
              :key="s.id"
              :header="s.name"
              :id="s.id"
              style="border: 1px solid"
            >
              <AmChart
                :element="'home-chart-' + s.id"
                type="radial-pie"
                :option="[
                  {
                    country: 'Réponses',
                    value: Math.random() * (100 - 10) + 10,
                  },
                  {
                    country: this.$t('noAnswer'),
                    value: Math.random() * (100 - 10) + 10
                  },
                  {
                    country: 'Total',
                    value: Math.random() * (100 - 10) + 10
                  }
                ]"
                :height="280"
              />

            </b-card> -->
            <b-card
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
              align="center"
              header="Management"
              id="1"
              style="border: 1px solid"
            >
              <AmChart
                :element="'home-chart-' + 1"
                type="radial-pie"
                :option="[
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                ]"
                :height="280"
              />
            </b-card>
            <b-card
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
              align="center"
              header="Gestion des médicaments"
              id="2"
              style="border: 1px solid"
            >
              <AmChart
                :element="'home-chart-' + 2"
                type="radial-pie"
                :option="[
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                ]"
                :height="280"
              />
            </b-card>
            <b-card
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
              align="center"
              header="Gestions des résidents"
              id="3"
              style="border: 1px solid"
            >
              <AmChart
                :element="'home-chart-' + 3"
                type="radial-pie"
                :option="[
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                  {
                    country: '',
                    value: Math.random() * (100 - 10) + 10,
                  },
                ]"
                :height="280"
              />
            </b-card>
          </b-card-group>
        </div>
      </iq-card>
      <iq-card>
        <div class="iq-card-header">
          <div class="iq-header-title">
            <h4 class="text-primary pt-3">
              Taux de participation et Nb d'utilisateurs par service
            </h4>
          </div>
        </div>
        <div class="iq-card-body">
          <ApexChart :element="chart.type" :chartOption="chart.bodyData" />
        </div>
      </iq-card>
    </div>
  </b-container>
  <mngDashboard v-else-if="user.roles.includes('MANAGER')" />
  <stdDashboard v-else-if="user.roles.includes('STANDARD')" />
</template>

<style>
.card-header {
  font-size: large;
}

/* #content-page { */
/* background-image: url("residence_cambre.jpg"); */
/* background-repeat: no-repeat; */
/* background-size: cover; */
/* } */

/* .iq-card {
  opacity: 0.93;
} */
</style>
