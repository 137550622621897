import axios from "./index";

export default {
  async login(userData) {
    return await axios.post("/api/login", userData);
  },
  async register(userData) {
    return await axios.post("/api/register", userData);
  },
  async getSession(token) {
    return await axios.get("api/session", {
      headers: { Authorization: `Bearer ${token}` }
    });
  },
  async logout(token) {
    return await axios.post(
      "api/logout",
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
  }
};
