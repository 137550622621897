<template>
  <div>
    <Alert />
    <h1 class="mb-0">Reset Mot de passe</h1>
    <p>
      Entrez votre nouveau mot de passe et confirmer le pour le mettre à jour.
    </p>
    <form class="mt-4" @submit="handleSubmit">
      <div class="form-group">
        <!-- <label for="exampleInputEmail1">E-Mail</label> -->
        <!-- <input
          type="text"
          class="form-control mb-0"
          id="email"
          v-model="email"
        /> -->
        <label for="exampleInputEmail1">Mot de passe</label>
        <input
          type="password"
          class="form-control mb-0"
          id="password"
          v-model="password"
        />
        <label for="exampleInputEmail1">Confirmer le mot de passe</label>
        <input
          type="password"
          class="form-control mb-0"
          id="password-confirmation"
          v-model="passwordConfirmation"
        />
      </div>

      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">
          Envoyer
        </button>
      </div>
    </form>

    <router-link :to="'/' + $i18n.locale + '/auth/login'"
      ><i class="fas fa-chevron-left" /> Retour au login</router-link
    >
  </div>
</template>
<script>
import ApiService from "../../../Utils/axios";
import axios from "axios";
import Alert from "../../../components/xray/alerts/Alert";
export default {
  name: "UpdatePassword1",
  props: ["formType"],
  data: () => ({
    // email:"",
    password: "",
    passwordConfirmation: "",
  }),
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      console.log([this.password, this.passwordConfirmation]);

      if (this.password != this.passwordConfirmation) {
        this.$store.dispatch("Alert/activate", {
          type: "danger",
          text: "Votre mot de passe ne correspond pas à la confirmation du mot de passe! Veuillez réessayer.",
        });
      } else {
        axios
          .post(process.env.VUE_APP_API + "/api/reset/password", {
            token: this.$route.params.token,
            // email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
          })
          .then((res) => {
            this.response = res.data;
            console.log(res.data);
            if (res.data.status == "error") {
              this.$store.dispatch("Alert/activate", {
                type: "danger",
                text: res.data.message,
              });
            } else {
              this.$store.dispatch("Alert/activate", {
                type: "success",
                text: "Votre mot de passe a bien été modifié.",
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
            this.$store.dispatch("Alert/activate", {
              type: "danger",
              text: "Une erreur est survenue lors de la réinitialisation de votre mot de passe",
            });
          });
      }
    },
  },
};
</script>
