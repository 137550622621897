<script>
import ApiService from "../../Utils/axios";
import Table from "./EditableTable";
import Loader from "../../components/xray/Loader";
import { mapGetters } from "vuex";
export default {
  components: { Table, Loader },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  data() {
    return {
      list: [],
      done: false,
      columns: [
        {
          label: "Référence",
          key: "id",
          class: "text-left w-25",
        },
        { label: "Intitulé", key: "title", class: "text-left" },
        {
          label: "Action",
          key: "action",
          class: "text-center w-25",
        },
      ],
    };
  },
  beforeMount() {
    this.fetchList();
  },
  mounted() {
    document.title = `${this.$t("pageTitles.articles")} - Auditor`;
  },
  methods: {
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/post")
        .then((res) => {
          this.list = res.data;
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div>
    <Table
      v-if="done && list.length"
      :addBtn="{
        text: $t('buttons.add'),
        link: '/post/create',
      }"
      :tableTitle="$t('pageTitles.guides')"
      :rows="list"
      :cols="columns"
      :fetchList="fetchList"
    />
    <b-container
      v-if="done && !list.length"
      style="height: 75vh"
      class="text-center"
      fluid
      ><h5>Aucun article trouvé dans notre base de données.</h5>
      <b-button
        v-if="user.roles.includes('ADMIN')"
        class="mt-3"
        variant="primary"
        :to="'/' + $i18n.locale + '/post/create'"
        ><i class="fas fa-plus"></i> Ajouter un article
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>

<style>
.w-5 {
  max-width: 5%;
}
</style>
