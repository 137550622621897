<template>
  <b-modal
    :id="'modal-' + crit.id"
    size="lg"
    title="Pieces jointes"
    :static="true"
    ok-title="Valider"
    ok-only
    @hidden="saveFiles(files)"
  >
    <p>{{ crit.refernce }} - {{ crit.question }}</p>
    <b-row class="mt-3" v-for="(file, i) of files" :key="i">
      <b-col
        ><b-form-file
          :class="'file-input inputFile_' + crit.id"
          placeholder="Piéce jointe"
          v-model="files[i].input"
          :name="`upfiles[${crit.id}][]`"
        ></b-form-file
      ></b-col>
      <b-col>
        <b-form-input
          :name="`filename[${crit.id}][]`"
          style="max-height: 35px; border-radius: 5px"
          size="sm"
          :state="files[i].title.length >= 3"
          class="file-title"
          :placeholder="$t('inputs.docName')"
          v-model="files[i].title"
          aria-describedby="feedback"
          trim
        ></b-form-input>
        <b-form-invalid-feedback id="feedback">
          {{ $t("inputs.docNameErr") }}
        </b-form-invalid-feedback></b-col
      >
      <b-col sm="2">
        <b-button
          style="max-width: 40px"
          variant="danger"
          @click="deleteFile(i)"
          v-if="files.length > 1"
        >
          <i class="fas fa-trash-alt"></i>
        </b-button>
      </b-col>
    </b-row>
    <b-button class="mt-3" variant="primary" @click="addFile()">
      <i class="fas fa-plus"></i> {{ $t("buttons.add") }}
    </b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    crit: Object,
  },

  data() {
    return {
      files: [
        {
          title: "",
          input: null,
        },
      ],
    };
  },
  methods: {
    addFile() {
      this.files.push({
        title: "",
        input: null,
      });
    },
    deleteFile(index) {
      if (this.files.length === 1) {
        return;
      }

      this.files = this.files.filter((e, i) => {
        return index !== i;
      });
    },
    saveFiles(files) {
      this.$store.dispatch("Survey/saveFiles", {
        files: files,
        crit_id: this.crit.id,
      });
    },
  },
};
</script>
