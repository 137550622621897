<script>
import ApiService from "../../Utils/axios";
import Table from "./EditableTable";
import Loader from "../../components/xray/Loader";

export default {
  components: { Table, Loader },
  data() {
    return {
      list: [],
      done: false,
      columns: [
        { editable: true, deleteable: true },
        { label: "Nom", key: "name", class: "text-left" },
        { label: "Catégorie", key: "category", class: "text-center" },
        { label: "Localité", key: "city", class: "text-center" },
        {
          label: "Action",
          key: "action",
          class: "text-center",
        },
      ],
    };
  },
  beforeMount() {
    this.fetchList();
  },
  mounted() {
    document.title = this.$t("pageTitles.institution");
  },
  methods: {
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/institution")
        .then((res) => {
          this.list = res.data.data;
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div>
    <Table
      v-if="done && list.length"
      :addBtn="{ text: $t('buttons.add'), link: '/institution/create' }"
      :tableTitle="$t('pageTitles.institution')"
      :rows="list"
      :cols="columns"
      :fetchList="fetchList"
    />
    <b-container
      v-if="done && !list.length"
      style="height: 75vh"
      class="text-center"
      fluid
      ><h5>Aucun établissement trouvé dans notre base de données.</h5>
      <b-button
        class="mt-3"
        variant="primary"
        :to="'/' + $i18n.locale + '/institution/create'"
        ><i class="fas fa-plus"></i> Ajouter un établissement
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>
