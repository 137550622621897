<script>
import ApiService from "../../Utils/axios";
import Table from "./EditableTable";
import Loader from "../../components/xray/Loader";

export default {
  components: { Table, Loader },
  data() {
    return {
      list: [],
      done: false,
      columns: [
        {
          label: "Référence",
          key: "reference",
          class: "text-left w-5",
        },
        { label: "Intitulé", key: "title", class: "text-left" },
        { label: "Description", key: "description", class: "text-left" },
        {
          label: "Action",
          key: "action",
          class: "text-center",
        },
      ],
    };
  },
  beforeMount() {
    this.fetchList();
  },
  mounted() {
    document.title = `${this.$t("pageTitles.function")} - Auditor`;
  },
  methods: {
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/position")
        .then((res) => {
          this.list = res.data;
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div>
    <Table
      v-if="done && list.length"
      :addBtn="{
        text: $t('buttons.add'),
        link: '/function/create',
      }"
      :tableTitle="$t('pageTitles.function')"
      :rows="list"
      :cols="columns"
      :fetchList="fetchList"
    />
    <b-container
      v-if="done && !list.length"
      style="height: 75vh"
      class="text-center"
      fluid
      ><h5>Aucune fonction trouvé dans notre base de données.</h5>
      <b-button
        class="mt-3"
        variant="primary"
        :to="'/' + $i18n.locale + '/function/create'"
        ><i class="fas fa-plus"></i> {{ $t("buttons.add") }}
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>

<style>
.w-5 {
  max-width: 5%;
}
</style>
