<script>
import ApiService from "../../Utils/axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function uploader(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}

export default {
  mounted() {
    document.title = "Modifier un article - Auditor";
  },
  data() {
    return {
      post: undefined,
      // content: "",
      title: "",
      content: "",
      editor: ClassicEditor, //DecoupledEditor
      editorConfig: {
        toolbar: {
          items: [
            "imageupload",
            "ckfinder",
            "|",
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "|",
            "bold",
            "italic",
            "blockQuote",
            "link",
            "alignment",
            "numberedList",
            "bulletedList",
            "insertTable",
            "mergeTableCells",
            "undo",
            "redo",
            "|",
            "code",
          ],

          shouldNotGroupWhenFull: true,
        },
        ckfinder: {
          uploadUrl: `${process.env.VUE_APP_API}/api/ckeditor/upload`,
        },
        extraPlugins: [uploader],
      },
      guides: [],
      guideId: null,
      chapters: [],
      chapterId: null,
      chapterTitle: null,
      crit: [],
      critId: null,
      selectedCrits: [],
    };
  },
  beforeMount() {
    this.fetchPost();
    this.fetchGuides();
  },
  methods: {
    fetchCrit() {
      new ApiService()
        .get(`/api/chapter/${this.chapterId}/list`)
        .then((res) => {
          this.crit = res.data.data.crteria;
          console.log(this.crit);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchChapters() {
      new ApiService()
        .get(`/api/guide/${this.guideId}/list`)
        .then((res) => {
          this.chapters = res.data.data.chapters;
          console.log(this.chapters);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchGuides() {
      new ApiService()
        .get("/api/guide")
        .then((res) => {
          this.guides = res.data.data.sort((a, b) => a.reference - b.reference);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchPost() {
      const { id } = this.$route.params;
      new ApiService()
        .get("/api/post/" + id)
        .then(({ data }) => {
          this.post = data.data;
          this.content = data.data.content;
          this.selectedCrits = data.data.criteria;
          this.title = data.data.title;
          console.log(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    chooseChapter(chapter) {
      this.chapterId = chapter.id;
      this.chapterTitle = chapter.title;
      this.fetchCrit();
    },
    chooseCrit(crit) {
      this.critId = crit;
      console.log(this.critId);
    },
    addCrit() {
      if (this.selectedCrits.find((e) => e.id == this.critId.id)) {
        this.$bvModal.show("modal-1");
        return;
      }
      this.selectedCrits = [
        ...this.selectedCrits,
        {
          id: this.critId.id,
          question: this.critId.question,
          refernce: this.critId.refernce,
          chapter: {
            id: this.chapterId,
            title: this.chapterTitle,
            guide_title: this.guides.filter((e) => this.guideId == e.id)[0]
              .title,
            guide_id: this.guideId,
          },
        },
      ];
    },
    removeCrit(id) {
      this.selectedCrits = this.selectedCrits.filter((item) => item.id !== id);
    },
    onReady(editor) {
      const toolbarContainer = document.querySelector(
        ".document-editortoolbar"
      );
      toolbarContainer.appendChild(editor.ui.view.toolbar.element);
    },
    handleSubmit(e) {
      e.preventDefault();
      const body = {
        title: e.target[0].value,
        criteria_id: this.selectedCrits.map((e) => e.id),
        content: this.content,
      };
      const { id } = this.$route.params;
      new ApiService()
        .put("/api/post/" + id, body)
        .then((res) => {
          this.$router.push("/" + this.$i18n.locale + "/post");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Article modifié avec succée",
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.$router.push("/" + this.$i18n.locale + "/post");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-modal id="modal-1" title="Erreur!" ok-only @ok="remove(item)">
      <p class="my-4 text-danger">Ce critére est deja relié avec cet article</p>
    </b-modal>
    <iq-card v-if="post">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">
            Modifier l'article
            <span class="text-primary">{{ title }}</span>
          </h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group">
          <label>Intitulé</label>
          <input
            type="text"
            name="title"
            class="form-control"
            placeholder="Intitulé"
            v-model="post.title"
            required
          />
        </div>

        <div class="form-group">
          <label>Contenu</label>
          <div class="document-editortoolbar"></div>
          <div class="document-editoreditable-container">
            <ckeditor
              :editor="editor"
              v-model="content"
              :config="editorConfig"
              @ready="onReady"
            ></ckeditor>
          </div>
        </div>

        <div class="my-5">
          <label>Ajouter critéres</label>
          <div class="d-flex border p-2">
            <div class="form-group col-lg-3">
              <label>Guides</label>
              <b-form-select @change="fetchChapters" v-model="guideId">
                <b-form-select-option default :value="null"
                  >--- Choisir guide ---</b-form-select-option
                >
                <b-form-select-option
                  v-for="e of guides"
                  :key="e.id"
                  :value="e.id"
                  >{{ e.title }}</b-form-select-option
                >
              </b-form-select>
            </div>

            <div v-if="guideId" class="form-group col-lg-4">
              <label>Chapitres / sections</label>
              <div class="autofill">
                <ul>
                  <li v-for="e of chapters" :key="e.id">
                    <span
                      @click="chooseChapter(e)"
                      style="border-bottom: 1px solid gray"
                      :class="
                        chapterId === e.id
                          ? 'autofill-item chosen'
                          : 'autofill-item'
                      "
                      v-if="!e.section.length"
                      >{{ e.reference + " - " + e.title }}</span
                    >
                    <ul v-else>
                      <li
                        @click="chooseChapter(s)"
                        style="border-bottom: 1px solid gray"
                        :class="
                          chapterId === s.id
                            ? 'autofill-item chosen'
                            : 'autofill-item'
                        "
                        v-for="s of e.section"
                        :key="s.id"
                      >
                        {{ s.refernce + " - " + s.title }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="chapterId" class="form-group col-lg-5">
              <label>Critéres / sous critéres</label>
              <div class="autofill">
                <ul>
                  <li
                    v-for="e of crit"
                    :key="e.id"
                    style="border-bottom: 1px solid gray"
                  >
                    <span
                      @click="chooseCrit(e)"
                      :class="
                        critId && critId.id === e.id
                          ? 'autofill-item chosen'
                          : 'autofill-item'
                      "
                      v-if="!e.subcriteria.length"
                      >{{ e.refernce + " - " + e.question }}</span
                    >
                    <ul v-else>
                      <li
                        style="border-bottom: 1px solid gray"
                        :class="
                          critId && critId.id === s.id
                            ? 'autofill-item chosen'
                            : 'autofill-item'
                        "
                        v-for="s of e.subcriteria"
                        :key="s.id"
                        @click="chooseCrit(s)"
                      >
                        <span> {{ s.refernce + " - " + s.question }}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <b-button
                type="button"
                variant="primary"
                v-if="critId"
                @click="addCrit()"
                class="pull-right mt-3"
                ><i class="fas fa-plus" />Ajouter</b-button
              >
            </div>
          </div>
        </div>

        <label>Critéres</label>
        <table
          v-if="selectedCrits.length"
          class="table table-hover table-bordered mb-5"
        >
          <thead>
            <tr>
              <th style="width: 8%">Réference</th>
              <th style="width: 40%">Critére</th>
              <th>Guide</th>
              <th>Chapitre</th>
              <th style="width: 8%">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="crit of selectedCrits" :key="crit.id">
              <td>{{ crit.refernce }}</td>
              <td>{{ crit.question }}</td>
              <td>{{ crit.chapter.guide_title }}</td>
              <td>{{ crit.chapter.title }}</td>
              <td class="text-center">
                <b-button
                  @click="removeCrit(crit.id)"
                  variant=" iq-bg-danger mt-1"
                  size="sm"
                  ><i class="ri-delete-bin-line m-0"></i
                ></b-button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="p-3 border text-center mb-5" v-else>
          <h3>Aucun critére relié avec cet article</h3>
        </div>

        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>

<style>
.style-chooser .vs__dropdown-toggle {
  height: 45px;
  border-radius: 10px;
}

.required .vs__dropdown-toggle,
.required .vs__dropdown-menu {
  border-color: red;
}

.ql-editor {
  min-height: 200px;
}

.autofill {
  height: 300px;
  background: white;
  z-index: 10;
  overflow-y: scroll;
  border-radius: 10px;
  border: 1px solid #d7dbda;
}
.autofill-item {
  cursor: pointer;
  width: 100%;
  color: #3f414d;
  padding: 4px;
}
.autofill-item:hover {
  background: #d9dddc88;
}
ul {
  padding-inline-start: 10px !important;
}
.chosen {
  background: #15ff4788;
}
</style>
