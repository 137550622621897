<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              {{ $t("pageTitles.surveys") }}
            </h4>
          </template>
          <template v-slot:body>
            <b-row class="d-flex justify-content-center">
              <b-col
                class="mb-3"
                xl="4"
                lg="4"
                md="6"
                v-for="item of rows"
                :key="item.id"
              >
                <div
                  class="card border shadow-sm rounded bg-primary"
                  style="min-height: 220px"
                >
                  <div class="card-body">
                    <h4 class="text-white">{{ item.title }}</h4>
                    <hr style="background-color: white" />
                    <div class="text-center" v-if="item.assessments.length">
                      <span>
                        {{ item.assessments[0].progression_percent }} %</span
                      >
                    </div>
                    <b-progress height="1.5rem" v-if="item.assessments.length">
                      <b-progress-bar
                        :value="item.assessments[0].progression_percent"
                        variant="success"
                      >
                      </b-progress-bar
                    ></b-progress>
                  </div>
                  <div
                    class="card-footer d-flex justify-content-between"
                    style="border-top-color: grey"
                  >
                    <b-button
                      variant=" iq-bg-danger"
                      size="sm"
                      v-if="item.assessments.length"
                      :title="$t('buttons.results')"
                      :to="
                        '/' +
                        $i18n.locale +
                        `/assessment/result/${item.assessments[0].id}`
                      "
                      ><i class="ri-bar-chart-fill m-0"></i>
                      {{ $t("buttons.myResults") }}</b-button
                    >
                    <b-button
                      class="bg-light text-dark"
                      size="sm"
                      variant="primary"
                      @click="assessmentExist(item.id)"
                      ><i class="fas fa-play"></i> Démarrer</b-button
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import { xray } from "../../config/pluginInit";
import ApiService from "../../Utils/axios";

export default {
  props: {
    rows: Array,
    cols: Array,
  },
  mounted() {
    console.log(this.rows, "hess");
  },
  methods: {
    async assessmentExist(survey_id) {
      const api = new ApiService();
      const exist = await api.get(`/api/survey/${survey_id}/exist`);
      if (exist.data.assessment_id) {
        this.$router.push(
          "/" +
            this.$i18n.locale +
            `/assessment/start?survey_id=${survey_id}&assessment_id=${exist.data.assessment_id}`
        );
      } else {
        api
          .post(`/api/assessment`, {
            survey_id: survey_id,
          })
          .then((res) => {
            this.$router.push(
              "/" +
                this.$i18n.locale +
                `/survey/start?survey_id=${survey_id}&assessment_id=${res.data.id}`
            );
          })
          .catch((e) => console.log(e.response));
      }
    },
  },
};
</script>

<style>
.list-item:hover {
  background: #eeeeee;
}
.list-item:not(:first-of-type) {
  border-top: 1px solid rgb(110, 110, 110);
  border-right: 1px solid rgb(110, 110, 110);
  border-left: 1px solid rgb(110, 110, 110);
  padding: 20px;
}
.list-item:last-of-type {
  border-bottom: 1px solid rgb(110, 110, 110);
}
</style>
