<script>
import ApiService from "../../Utils/axios";
export default {
  mounted() {
    document.title = "Modifier un service - Auditor";
  },
  data() {
    return { service: { name: "", description: "", reference: "" } };
  },
  beforeMount() {
    this.fetchService();
  },
  methods: {
    fetchService() {
      new ApiService()
        .get("/api/service/" + this.$route.query.service_id)
        .then((res) => {
          this.service = res.data.data;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      let body = {
        name: e.target[0].value,
        reference: e.target[1].value,
        description: e.target[2].value,
        institution_id: this.$route.query.institution_id,
      };
      new ApiService()
        .put("/api/service/" + this.$route.query.service_id, body)
        .then((res) => {
          this.$router.push("/" + this.$i18n.locale + "/institution");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Service modifié avec succée",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/" + this.$i18n.locale + "/institution");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">
            Modifier le service
            <span class="text-primary">{{ service.name }} </span>
          </h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group row">
          <div class="col">
            <label>Nom</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nom"
              v-model="service.name"
              required
            />
          </div>
          <div class="col">
            <label>Abreviation</label>
            <input
              type="text"
              class="form-control"
              placeholder="Abreviation"
              v-model="service.reference"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <label>Description</label>
          <textarea
            class="form-control"
            placeholder="Description"
            v-model="service.description"
            required
          ></textarea>
        </div>

        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>
