<script>
import Table from "./EditableTable";
import Loader from "../../components/xray/Loader";
import ApiService from "../../Utils/axios";
import { mapGetters } from "vuex";
export default {
  name: "guide-list",
  components: { Table, Loader },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  mounted() {
    document.title = `${this.$t("pageTitles.guides")} - Auditor`;
  },
  beforeMount() {
    this.fetchList();
  },
  data() {
    return {
      list: [],
      modalMsg: "",
      done: false,
      item: null,
      columns: [
        { editable: true, deleteable: true },
        { label: "Référence", key: "reference", class: "text-center" },
        { label: "Intitulé", key: "title", class: "text-left" },
        // { label: "Resumé", key: "summary", class: "text-left" },
        {
          label: "Action",
          key: "action",
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/guide/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Guide supprimé avec succée",
            type: "success",
          });
        })
        .catch(() => {
          this.$store.dispatch("Alert/activate", {
            text: "Le guide ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
            type: "danger",
          });
        });
    },
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.title;
      this.$bvModal.show("modal-1");
    },
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/guide")
        .then((res) => {
          const data = Array.isArray(res.data) ? res.data : res.data.data;
          this.list = data.sort((a, b) => a.reference - b.reference);
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div>
    <b-modal id="modal-1" title="Attention!" @ok="remove(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer l'établissement
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal>
    <!-- <Table
      tableTitle="Liste des guides"
      v-if="done && list.length"
      :addBtn="{
        text:: $t('buttons.add') 'Ajouter un guide',
        link: '/guide/create'
      }"
      :rows="list"
      :cols="columns"
    /> -->
    <b-container v-if="done" fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary">{{ $t("pageTitles.guides") }}</h4>
        </template>
        <template v-slot:headerAction>
          <b-button
            class="m-2"
            variant="primary"
            style="white-space: nowrap"
            :to="'/' + $i18n.locale + '/guide/create'"
            v-if="user.roles.includes('ADMIN')"
            ><i class="fas fa-plus"></i> {{ $t("buttons.add") }}</b-button
          ></template
        >
        <div class="iq-card-body">
          <b-row
            v-if="done && list.length"
            class="d-flex justify-content-center"
          >
            <b-col class="mb-3" md="4" v-for="item of list" :key="item.id">
              <div
                class="card border shadow rounded"
                :style="
                  'min-height: 220px; background-color:#' + item.category.color
                "
              >
                <div class="card-body">
                  <router-link
                    :to="'/' + $i18n.locale + '/guide/show/' + item.id"
                    ><h5 class="text-white card-title">
                      {{ item.title }}
                    </h5></router-link
                  >
                  <hr style="background-color: white" />
                  <span style="color: goldenrod"
                    >Réference: {{ item.reference }}</span
                  >
                </div>
                <!-- border-top-color: #044790; (Number(item.category.color) + 131586).toString(16)-->
                <div
                  class="card-footer d-flex justify-content-between"
                  :style="
                    'border-radius: 0 0 20px 20px;  background-color:#' +
                    item.category.color
                  "
                >
                  <div v-if="item.status == 'created'">
                    <!-- <i class="fas fa-cogs fs-3" title="Encours de rédaction" style="font-size: 1.2rem;"></i> -->
                  </div>
                  <div v-else-if="item.status == 'validated'">
                    <i
                      class="fas fa-check text-success"
                      title="Validé"
                      style="font-size: 1.2rem"
                    ></i>
                  </div>
                  <div v-else>
                    <i
                      class="fas fa-file-archive"
                      title="Archivé"
                      style="font-size: 1.2rem; color: black"
                    ></i>
                  </div>

                  <div class="">
                    <b-button
                      :to="'/' + $i18n.locale + '/guide/show/' + item.id"
                      variant=" iq-bg-primary mr-1 mt-1"
                      size="sm"
                      ><i class="fas fa-book-open m-0"></i
                    ></b-button>
                    <b-button
                      v-if="
                        user.roles.includes('ADMIN') ||
                        user.roles.includes('SUPER_ADMIN')
                      "
                      variant=" iq-bg-success mr-1 mt-1"
                      size="sm"
                      :to="'/' + $i18n.locale + '/guide/edit/' + item.id"
                      ><i class="ri-ball-pen-fill m-0"></i
                    ></b-button>
                    <b-button
                      v-if="
                        user.roles.includes('ADMIN') ||
                        user.roles.includes('SUPER_ADMIN')
                      "
                      variant=" iq-bg-danger mr-1 mt-1"
                      size="sm"
                      @click="onRemove(item)"
                      ><i class="ri-delete-bin-line m-0"></i
                    ></b-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </iq-card>
    </b-container>
    <b-container
      v-if="done && !list.length"
      style="height: 75vh"
      class="text-center"
      fluid
      ><h5>Aucun guide trouvé dans notre base de données.</h5>
      <b-button
        class="mt-3"
        variant="primary"
        :to="'/' + $i18n.locale + '/guide/create'"
        ><i class="fas fa-plus"></i> Ajouter un guide
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>
