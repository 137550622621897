<template>
  <b-container fluid>
    <iq-card class="text-center" style="padding: 20%">
      <img :src="loader" alt="loader" style="width: 6.5rem" class="mx-auto" />
    </iq-card>
  </b-container>
</template>

<script>
import loader from "../../assets/images/loaderLoop.gif";
export default {
  data() {
    return {
      loader: loader
    };
  }
};
</script>
