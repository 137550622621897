<script>
import ApiService from "../../Utils/axios";
import Multiselect from "vue-select";
export default {
  mounted() {
    document.title = "Modifier un utilisateur - Auditor";
  },
  components: { MultiS: Multiselect },
  data() {
    return {
      inst: [],
      pos: [],
      roles: [],
      selectedInst: null,
      user: null,
      roles: [],
      selectedRoles: [],
      selectedPos: [],
      confirm: "",
      password: "",
    };
  },
  beforeMount() {
    this.fetchUser();
    this.fetchInst();
    this.fetchPos();
    this.fetchRoles();
  },
  methods: {
    fetchUser() {
      const { id } = this.$route.params;
      new ApiService()
        .get("/api/user/" + id)
        .then((res) => {
          this.user = res.data.data;
          this.selectedRoles = res.data.data.roles;
          this.selectedPos = res.data.data.positions.map((e) => e.id);
          this.fetchServ(res.data.data.service.id);
          console.log(this.user);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchInst() {
      new ApiService()
        .get("/api/institution")
        .then((res) => {
          this.inst = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchRoles() {
      new ApiService()
        .get("/api/allroles")
        .then((res) => {
          this.roles = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchServ(id) {
      new ApiService()
        .get("/api/service/" + id)
        .then((res) => {
          this.selectedInst = res.data.data.institution;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchPos() {
      new ApiService()
        .get("/api/position")
        .then((res) => {
          this.pos = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      if (!this.selectedRoles.length || this.password != this.confirm) {
        return;
      }
      let body = {
        name: e.target[0].value,
        isauditor: this.user.isauditor,
        email: e.target[2].value,
        service_id: e.target[6].value,
        role: this.selectedRoles,
        positions: this.selectedPos,
      };
      if (e.target[3].value) {
        body.password = e.target[3].value;
      }

      console.log(body);

      const { id } = this.$route.params;
      new ApiService()
        .put("/api/user/" + id, body)
        .then((res) => {
          this.$router.push("/" + this.$i18n.locale + "/user");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Utilisateur modifié avec succée",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/" + this.$i18n.locale + "/user");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">
            Modifier l'utilisateur
            <span v-if="user" class="text-primary">{{ user.name }}</span>
          </h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Nom</label>
            <input
              v-if="user"
              v-model="user.name"
              type="text"
              class="form-control"
              placeholder="Nom"
              required
            />
            <b-form-checkbox
              v-model="user.isauditor"
              :value="1"
              :unchecked-value="0"
              class="mt-2"
            >
              Est auditeur externe
            </b-form-checkbox>
          </div>
          <div class="col-lg-6">
            <label>E-mail</label>
            <input
              v-model="user.email"
              v-if="user"
              type="email"
              class="form-control"
              placeholder="E-mail"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label>Mot de passe</label>
            <input
              type="password"
              class="form-control"
              placeholder="password"
              autocomplete="off"
              v-model="password"
            />
          </div>
          <div class="col-lg-6">
            <label>Confirmation de mot de passe</label>
            <b-form-input
              type="password"
              placeholder="Confirmation"
              autocomplete="off"
              :state="confirm == password"
              v-model="confirm"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label>Etablissement</label>
            <select class="form-control" v-model="selectedInst" required>
              <option v-for="i of inst" :key="i.id" :value="i">
                {{ i.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-6">
            <label>Service</label>
            <select
              v-if="selectedInst && selectedInst.services.length"
              v-model="user.service.id"
              class="form-control"
              required
            >
              <option
                v-for="s of selectedInst.services"
                :key="s.id"
                :value="s.id"
              >
                {{ s.name }}
              </option>
            </select>
            <select
              class="form-control"
              v-if="selectedInst && !selectedInst.services.length"
              disabled
            >
              <option>L'établissement ne contient aucun service.</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label>Fonctions</label>
            <MultiS
              :options="pos"
              :reduce="(pos) => pos.id"
              label="title"
              v-model="selectedPos"
              :class="'style-chooser'"
              multiple
            />
          </div>
          <div class="col-lg-6">
            <label>Rôles</label>
            <MultiS
              :options="roles"
              :reduce="(role) => role.id"
              v-model="selectedRoles"
              label="name"
              :class="
                selectedRoles.length
                  ? 'style-chooser'
                  : 'style-chooser required'
              "
              multiple
            />
            <span class="text-danger m-2" v-if="!selectedRoles.length"
              >Les roles sont requis</span
            >
          </div>
        </div>

        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>

<style>
.style-chooser .vs__dropdown-toggle {
  height: 45px;
  border-radius: 10px;
}

.required .vs__dropdown-toggle,
.required .vs__dropdown-menu {
  border-color: red;
}
</style>
