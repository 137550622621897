<template>
  <router-view />
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "App",
  components: {},
  mounted() {},
};
</script>
<style lang="scss">
@import "assets/scss/style.scss";
@import "vue-select/src/scss/vue-select.scss";
.slider__label {
  top: -40px !important;
  background: #054690 !important;
  color: white !important;
  border-radius: 5px;
}
.slider__label:after {
  border-top-color: #054690 !important;
}
.slider__track {
  background: #054690 !important;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ff7700 !important;
  border: 3px solid #054690;
}
</style>
