<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <span class="text-danger">{{ loginErr }}</span>
      <ValidationProvider
        vid="email"
        name="E-mail"
        rules="required|email"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="emailInput">E-mail</label>
          <input
            type="email"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="emailInput"
            aria-describedby="emailHelp"
            v-model="user.email"
            placeholder="nom@exemple.com"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="passwordInput">{{ $t("password") }}</label>
          <router-link
            :to="'/' + $i18n.locale + '/auth/password-reset'"
            class="float-right"
          >
            {{ $t("buttons.password") }}
          </router-link>
          <div class="input-group mb-3">
            <input
              :type="hide ? 'password' : 'text'"
              :class="
                'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
              "
              id="passwordInput"
              v-model="user.password"
              placeholder="mot de passe"
              required
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                title="Voir/Cacher le mot de passe"
                type="button"
                @click="hide = !hide"
                id="button-addon2"
              >
                <i :class="hide ? 'fas fa-eye' : 'fas fa-eye-slash'" />
              </button>
            </div>
          </div>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input
            type="checkbox"
            class="custom-control-input"
            v-model="remember"
            :id="formType"
          />
          <label class="custom-control-label" :for="formType">{{
            $t("stayOnline")
          }}</label>
        </div>
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-primary float-right"
        >
          <span v-if="!loading"> {{ $t("buttons.login") }}</span>
          <span v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Connexion...</span
          >
        </button>

        <div class="b-row">
          <img
            :src="euromedi_logo"
            class="img-fluid mt-5"
            alt="logo"
            style="max-width: 250px"
          />
        </div>
      </div>
      <!-- <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2">
          Don't have an account?
          <router-link
            to="/auth/register"
            class="iq-waves-effect pr-4"
            v-if="$route.meta.dark"
          >
            Sign up
          </router-link>
          <router-link to="/auth/register" class="iq-waves-effect pr-4" v-else>
            Sign up
          </router-link>
        </span>
        <social-login-form></social-login-form>
      </div> -->
    </form>
  </ValidationObserver>
</template>

<script>
import SocialLoginForm from "./SocialLoginForm";
import { mapGetters } from "vuex";

export default {
  name: "SignIn1Form",
  components: { SocialLoginForm },
  props: ["formType"],
  data: () => ({
    user: {
      email: "",
      password: "",
    },
    hide: true,
    remember: true,
    loginErr: "",
    loading: false,
    euromedi_logo: require("../../../../assets/images/euromedi_logo.png"),
  }),
  mounted() {
    document.title = "Login - Auditor";
  },
  computed: {
    ...mapGetters({
      stateUsers: "Setting/usersState",
      currentUser: "Auth/getCurrentUser",
    }),
  },
  methods: {
    onSubmit() {
      this.login();
    },
    async login() {
      this.loading = true;
      this.loginErr = "";
      const err = await this.$store.dispatch("Auth/login", {
        userData: this.user,
        remember: this.remember,
      });

      if (err) {
        this.loginErr = err;
      } else {
        this.$router.push("/" + this.$i18n.locale + "/");
      }

      this.loading = false;
    },
  },
};
</script>
