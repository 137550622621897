<script>
import ApiService from "../../Utils/axios";
// import SourceEditing from "@ckeditor/ckeditor5-source-editing";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function uploader(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}

export default {
  data: () => ({
    content: "",
    editor: ClassicEditor,
    editorConfig: {
      // plugins: [ SourceEditing],
      toolbar: [
        "imageupload",
        "ckfinder",
        "|",
        "heading",
        "|",
        "fontFamily",
        "fontSize",
        "|",
        "bold",
        "italic",
        "blockQuote",
        "link",
        "code",
        "alignment",
        "numberedList",
        "bulletedList",
        "insertTable",
        "mergeTableCells",
        "undo",
        "redo",
      ],
      ckfinder: {
        uploadUrl: `${process.env.VUE_APP_API}/api/ckeditor/upload`,
      },
      extraPlugins: [uploader],
    },
  }),

  mounted() {
    document.title = "Ajouter un article - Auditor";
  },
  methods: {
    onReady(editor) {
      const toolbarContainer = document.querySelector(
        ".document-editortoolbar"
      );
      toolbarContainer.appendChild(editor.ui.view.toolbar.element);
    },
    input(e) {
      console.log(e);
    },
    handleSubmit(e) {
      e.preventDefault();
      const body = {
        title: e.target[0].value,
        content: this.content,
      };
      new ApiService()
        .post("/api/post", body)
        .then((res) => {
          this.$router.push("/" + this.$i18n.locale + "/post");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Article ajouté avec succée",
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.$router.push("/" + this.$i18n.locale + "/post");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Ajouter un article</h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group">
          <label>Intitulé</label>
          <input
            type="text"
            name="title"
            class="form-control"
            placeholder="Intitulé"
            required
          />
        </div>

        <div class="form-group">
          <label>Contenu</label>
          <div class="document-editortoolbar"></div>
          <div class="document-editoreditable-container">
            <ckeditor
              @input="input"
              :editor="editor"
              v-model="content"
              :config="editorConfig"
              @ready="onReady"
            ></ckeditor>
          </div>
        </div>
        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>

<style>
.style-chooser .vs__dropdown-toggle {
  height: 45px;
  border-radius: 10px;
}

.ck-content {
  height: 400px;
}

.required .vs__dropdown-toggle,
.required .vs__dropdown-menu {
  border-color: red;
}
</style>
