<script>
import ApiService from "../../Utils/axios";
export default {
  mounted() {
    document.title = "Ajouter un guide - Auditor";
  },
  components: {},
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);

      new ApiService()
        .post("/api/guide", formData)
        .then((res) => {
          this.$router.push("/" + this.$i18n.locale + "/guide");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Guide ajouté avec succée",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/" + this.$i18n.locale + "/guide");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Ajouter un guide</h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Référence</label>
            <input
              name="reference"
              type="text"
              class="form-control"
              placeholder="Référence"
              required
            />
          </div>
          <div class="col-lg-6">
            <label>Intitulé</label>
            <input
              name="title"
              type="text"
              class="form-control"
              placeholder="Intitulé"
              required
            />
          </div>
          <div class="col-lg-6 mt-2">
            <label>Statut</label>
            <select name="status" class="form-control">
              <option value="created">Créé</option>
              <option value="validated">Validé</option>
              <option value="archived">Archivé</option>
            </select>
          </div>
          <div class="col-lg-6 mt-2">
            <label>Catégorie</label>
            <select name="gcategory_id" class="form-control">
              <option value="1">Management</option>
              <option value="2">Médical</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label>Description</label>
          <textarea
            name="summary"
            class="form-control"
            placeholder="Description"
            required
          ></textarea>
        </div>

        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>
