<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">{{ tableTitle }}</h4>
          </template>
          <template v-slot:headerAction>
            <div class="row" style="padding: 10px">
              <b-form-input
                id="filter-input"
                class="col m-2"
                style="width: 300px; height: 35px"
                v-model="filter"
                type="search"
                :placeholder="$t('inputs.search')"
              ></b-form-input>
              <b-button
                class="col m-2"
                variant="primary"
                style="white-space: nowrap"
                :to="'/' + $i18n.locale + addBtn.link"
                ><i class="fas fa-plus"></i> {{ addBtn.text }}</b-button
              >
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table
                  :filter="filter"
                  @filtered="onFiltered"
                  bordered
                  hover
                  :per-page="20"
                  :current-page="currentPage"
                  :items="rows"
                  :fields="cols"
                  id="my-table"
                  foot-clone
                >
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input
                      type="text"
                      v-model="data.item.name"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(position)="data">
                    <span v-if="!data.item.editable">{{
                      data.item.position
                    }}</span>
                    <input
                      type="text"
                      v-model="data.item.position"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(office)="data">
                    <span v-if="!data.item.editable">{{
                      data.item.office
                    }}</span>
                    <input
                      type="text"
                      v-model="data.item.office"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(age)="data">
                    <span v-if="!data.item.editable">{{ data.item.age }}</span>
                    <input
                      type="text"
                      v-model="data.item.age"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(start_date)="data">
                    <span v-if="!data.item.editable">{{
                      data.item.start_date
                    }}</span>
                    <input
                      type="text"
                      v-model="data.item.start_date"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(salary)="data">
                    <span v-if="!data.item.editable">{{
                      data.item.salary
                    }}</span>
                    <input
                      type="text"
                      v-model="data.item.salary"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      :to="'/' + $i18n.locale + '/guide/show/' + data.item.id"
                      variant=" iq-bg-primary mr-1 mt-1"
                      size="sm"
                      ><i class="fas fa-eye m-0"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-success mr-1 mt-1"
                      size="sm"
                      :to="'/' + $i18n.locale + 'guide/edit/' + data.item.id"
                      ><i class="ri-ball-pen-fill m-0"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-danger mr-1 mt-1"
                      size="sm"
                      @click="remove(data.item)"
                      ><i class="ri-delete-bin-line m-0"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3 pull-right"
                  v-model="currentPage"
                  :total-rows="rows.length"
                  :per-page="20"
                  aria-controls="my-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-1" title="Attention!" @ok="remove(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer l'établissement
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from "../../config/pluginInit";
import ApiService from "../../Utils/axios";

export default {
  name: "UiDataTable",
  props: {
    addBtn: Object,
    tableTitle: String,
    rows: Array,
    cols: Array,
    fetchList: Function,
  },
  mounted() {
    xray.index();
  },
  methods: {
    default() {
      return {
        id: this.rows.length,
        name: "",
        position: "",
        office: "",
        age: "",
        start_date: "2011/04/25",
        salary: "$0",
        editable: false,
      };
    },
    edit(item) {
      item.editable = true;
    },
    submit(item) {
      item.editable = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/guide/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Guide supprimé avec succée",
            type: "success",
          });
        })
        .catch(() => {
          this.$store.dispatch("Alert/activate", {
            text: "Le guide ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
            type: "danger",
          });
        });
    },
  },
  data() {
    return {
      currentPage: 1,
      filter: "",
      modalMsg: "",
      item: null,
    };
  },
};
</script>
