<script>
import ApiService from "../../Utils/axios";
import { quillEditor } from "vue-quill-editor";
export default {
  data() {
    return {
      post: {}
    };
  },
  beforeMount() {
    this.fetchPost();
  },
  methods: {
    fetchPost() {
      const { id } = this.$route.params;
      new ApiService()
        .get("/api/post/" + id)
        .then(({ data }) => {
          this.post = data.data;
          document.title = this.post.title + " - Auditor";
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title text-primary">
            {{ post.title }}
          </h4>
        </div>
      </div>
      <div v-html="post.content" class="iq-card-body"></div>
    </iq-card>
  </b-container>
</template>
