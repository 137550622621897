var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.done &&
      _vm.list.length &&
      (_vm.user.roles.includes('ADMIN') || _vm.user.roles.includes('SUPER_ADMIN'))
    )?_c('Table',{attrs:{"tableTitle":_vm.$t('pageTitles.results'),"rows":_vm.list,"cols":_vm.columns,"fetchList":_vm.fetchList}}):_vm._e(),(
      _vm.done &&
      _vm.list.length &&
      !_vm.user.roles.includes('ADMIN') &&
      !_vm.user.roles.includes('SUPER_ADMIN') &&
      _vm.user.roles.includes('MANAGER')
    )?_c('TableManager',{attrs:{"tableTitle":_vm.$t('pageTitles.results'),"rows":_vm.list,"cols":_vm.columnsMgr,"fetchList":_vm.fetchList}}):_vm._e(),(
      _vm.done &&
      _vm.list.length &&
      !_vm.user.roles.includes('ADMIN') &&
      !_vm.user.roles.includes('SUPER_ADMIN') &&
      (_vm.user.roles.includes('STANDARD') || _vm.user.roles.includes('GUEST'))
    )?_c('TableStandard',{attrs:{"tableTitle":_vm.$t('pageTitles.results'),"rows":_vm.list,"cols":_vm.columnsStd,"fetchList":_vm.fetchList}}):_vm._e(),(_vm.done && !_vm.list.length)?_c('b-container',{staticClass:"text-center",staticStyle:{"height":"75vh"},attrs:{"fluid":""}},[_c('h5',[_vm._v("Aucune evaluation trouvée dans notre base de données.")])]):_vm._e(),(!_vm.done)?_c('Loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }