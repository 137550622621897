<script>
import ApiService from "../../Utils/axios";
import Multiselect from "vue-select";
export default {
  components: { MultiS: Multiselect },
  mounted() {
    document.title = "Ajouter un questionnaire - Auditor";
  },
  data() {
    return {
      inst: [],
      selectedInst: null,
      guides: [],
      selectedGuides: [],
      selectedServices: [],
      level: "1",
    };
  },
  beforeMount() {
    this.fetchInst();
    this.fetchGuides();
  },
  methods: {
    fetchInst() {
      new ApiService()
        .get("/api/institution")
        .then((res) => {
          this.inst = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchGuides() {
      new ApiService()
        .get("/api/guide")
        .then((res) => {
          this.guides = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      if (!this.selectedServices.length || !this.selectedGuides.length) {
        return;
      }
      let body = {
        reference: e.target[0].value,
        title: e.target[1].value,
        summary: e.target[2].value,
        institution_id: this.selectedInst.id,
        services: this.selectedServices,
        level: this.level,
        guides: this.selectedGuides,
      };
      new ApiService()
        .post("/api/survey", body)
        .then((res) => {
          this.$router.push("/" + this.$i18n.locale + "/survey");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Questionnaire ajouté avec succée",
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.$router.push("/" + this.$i18n.locale + "/survey");
          this.$store.dispatch("Alert/activate", {
            type: "danger",
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
          });
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Ajouter un questionnaire</h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Référence</label>
            <input
              type="text"
              class="form-control"
              placeholder="Référence"
              required
            />
          </div>
          <div class="col-lg-6">
            <label>Intitulé</label>
            <input
              type="text"
              class="form-control"
              placeholder="Intitulé"
              required
            />
          </div>
        </div>

        <div class="form-group">
          <label>Resumé</label>
          <textarea
            class="form-control"
            placeholder="Resumé"
            required
          ></textarea>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label>Etablissement</label>
            <select class="form-control" v-model="selectedInst" required>
              <option v-for="i of inst" :key="i.id" :value="i">
                {{ i.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-6">
            <label>Services</label>
            <MultiS
              v-if="selectedInst && selectedInst.services.length"
              :options="selectedInst.services"
              :reduce="(ser) => ser.id"
              v-model="selectedServices"
              label="name"
              :class="
                selectedServices.length
                  ? 'style-chooser'
                  : 'style-chooser required'
              "
              multiple
            />
            <span
              class="text-danger m-2"
              v-if="selectedInst && !selectedServices.length"
              >Les services sont requis</span
            >
            <select
              class="form-control"
              v-if="selectedInst && !selectedInst.services.length"
              disabled
            >
              <option>L'établissement ne contient aucun service.</option>
            </select>

            <select class="form-control" v-if="!selectedInst" disabled>
              <option>Selectionner un établissement.</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label>Niveau</label>
            <select v-model="level" class="form-control" required>
              <option value="1">Niveau 1</option>
              <option value="2">Niveau 2</option>
              <option value="3">Niveau 3</option>
              <option value="4">Premium</option>
            </select>
          </div>
          <div class="col-lg-6">
            <label>Guides</label>
            <MultiS
              :options="guides"
              :reduce="(guide) => guide.id"
              v-model="selectedGuides"
              label="title"
              :class="
                selectedGuides.length
                  ? 'style-chooser'
                  : 'style-chooser required'
              "
              multiple
            />
            <span class="text-danger m-2" v-if="!selectedGuides.length"
              >Les guides sont requis</span
            >
          </div>
        </div>

        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>

<style>
.style-chooser .vs__dropdown-toggle {
  height: 45px;
  border-radius: 10px;
}

.required .vs__dropdown-toggle,
.required .vs__dropdown-menu {
  border-color: red;
}
</style>
