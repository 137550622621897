var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('iq-card',[_c('div',{staticClass:"iq-card-header d-flex justify-content-between"},[_c('div',{staticClass:"iq-header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Ajouter une grille d'evaluation")])])]),_c('form',{staticClass:"iq-card-body",on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-lg-6"},[_c('label',[_vm._v("Référence")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Référence","required":""}})]),_c('div',{staticClass:"col-lg-6"},[_c('label',[_vm._v("Intitulé")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Intitulé","required":""}})])]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Description")]),_c('textarea',{staticClass:"form-control",attrs:{"placeholder":"Description","required":""}})]),_c('div',{staticClass:"my-5"},[_c('label',[_vm._v("Ajouter critéres")]),_c('div',{staticClass:"row border p-2"},[_c('div',{staticClass:"form-group col-lg-3"},[_c('label',[_vm._v("Guides")]),_c('b-form-select',{on:{"change":_vm.fetchChapters},model:{value:(_vm.guideId),callback:function ($$v) {_vm.guideId=$$v},expression:"guideId"}},[_c('b-form-select-option',{attrs:{"default":"","value":null}},[_vm._v("--- Choisir guide ---")]),_vm._l((_vm.guides),function(e){return _c('b-form-select-option',{key:e.id,attrs:{"value":e.id}},[_vm._v(_vm._s(e.title))])})],2)],1),(_vm.guideId)?_c('div',{staticClass:"form-group col-lg-4"},[_c('label',[_vm._v("Chapitres / sections")]),_c('div',{staticClass:"autofill"},[_c('ul',_vm._l((_vm.chapters),function(e){return _c('li',{key:e.id},[(!e.section.length)?_c('span',{class:_vm.chapterId === e.id
                        ? 'autofill-item chosen'
                        : 'autofill-item',staticStyle:{"border-bottom":"1px solid gray"},on:{"click":function($event){return _vm.chooseChapter(e)}}},[_vm._v(_vm._s(e.reference + " - " + e.title))]):_c('ul',_vm._l((e.section),function(s){return _c('li',{key:s.id,class:_vm.chapterId === s.id
                          ? 'autofill-item chosen'
                          : 'autofill-item',staticStyle:{"border-bottom":"1px solid gray"},on:{"click":function($event){return _vm.chooseChapter(s)}}},[_vm._v(" "+_vm._s(s.refernce + " - " + s.title)+" ")])}),0)])}),0)])]):_vm._e(),(_vm.chapterId)?_c('div',{staticClass:"form-group col-lg-5"},[_c('label',[_vm._v("Critéres / sous critéres")]),_c('div',{staticClass:"autofill"},[_c('ul',_vm._l((_vm.crit),function(e){return _c('li',{key:e.id,staticStyle:{"border-bottom":"1px solid gray"}},[(!e.subcriteria.length)?_c('span',{class:_vm.critId && _vm.critId.id === e.id
                        ? 'autofill-item chosen'
                        : 'autofill-item',on:{"click":function($event){return _vm.chooseCrit(e)}}},[_vm._v(_vm._s(e.refernce + " - " + e.question))]):_c('ul',_vm._l((e.subcriteria),function(s){return _c('li',{key:s.id,class:_vm.critId && _vm.critId.id === s.id
                          ? 'autofill-item chosen'
                          : 'autofill-item',staticStyle:{"border-bottom":"1px solid gray"},on:{"click":function($event){return _vm.chooseCrit(s)}}},[_c('span',[_vm._v(" "+_vm._s(s.refernce + " - " + s.question))])])}),0)])}),0)]),(_vm.critId)?_c('b-button',{staticClass:"pull-right mt-3",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.addCrit()}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v("Ajouter")]):_vm._e()],1):_vm._e()])]),_c('label',[_vm._v("Critères")]),(_vm.selectedCrits.length)?_c('table',{staticClass:"table table-hover table-bordered mb-5"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"8%"}},[_vm._v("Réference")]),_c('th',{staticStyle:{"width":"40%"}},[_vm._v("Critère")]),_c('th',[_vm._v("Guide")]),_c('th',[_vm._v("Chapitre")]),_c('th',{staticStyle:{"width":"8%"}},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.selectedCrits),function(crit){return _c('tr',{key:crit.id},[_c('td',[_vm._v(_vm._s(crit.refernce))]),_c('td',[_vm._v(_vm._s(crit.question))]),_c('td',[_vm._v(_vm._s(crit.chapter.guide_title))]),_c('td',[_vm._v(_vm._s(crit.chapter.title))]),_c('td',{staticClass:"text-center"},[_c('b-button',{attrs:{"variant":" iq-bg-danger mt-1","size":"sm"},on:{"click":function($event){return _vm.removeCrit(crit.id)}}},[_c('i',{staticClass:"ri-delete-bin-line m-0"})])],1)])}),0)]):_c('div',{staticClass:"p-3 border text-center mb-5"},[_c('h3',[_vm._v("Aucun critére relié avec cet article")])]),_c('button',{staticClass:"btn iq-bg-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Annuler ")]),_c('button',{staticClass:"btn btn-primary mx-2",attrs:{"type":"submit"}},[_vm._v("Enregistrer")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }