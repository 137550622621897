import Vue from "vue";
import Vuex from "vuex";
import Setting from "./Setting/index";
import Chat from "./Chat/index";
import Auth from "./Auth/user";
import Alert from "./alerts/alert";
import Survey from "./Survey/index";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    Setting,
    Chat,
    Auth,
    Alert,
    Survey
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  strict: debug
});
