<script>
import ApiService from "../../Utils/axios";
import Multiselect from "vue-select";
export default {
  mounted() {
    document.title = "Ajouter un utilisateur - Auditor";
  },
  components: { MultiS: Multiselect },
  data() {
    return {
      inst: [],
      serv: [],
      pos: [],
      roles: [],
      selectedInst: null,
      selectedRoles: [],
      selectedPos: [],
      isauditor: false,
      confirm: "",
      password: "",
    };
  },
  beforeMount() {
    this.fetchInst();
    this.fetchPos();
    this.fetchRoles();
  },
  methods: {
    fetchInst() {
      new ApiService()
        .get("/api/institution")
        .then((res) => {
          this.inst = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchPos() {
      new ApiService()
        .get("/api/position")
        .then((res) => {
          this.pos = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchRoles() {
      new ApiService()
        .get("/api/allroles")
        .then((res) => {
          this.roles = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      if (!this.selectedRoles.length || this.password != this.confirm) {
        return;
      }
      let body = {
        name: e.target[0].value,
        isauditor: this.isauditor,
        email: e.target[2].value,
        password: e.target[3].value,
        service_id: e.target[6].value,
        role: this.selectedRoles,
        positions: this.selectedPos,
      };
      new ApiService()
        .post("/api/user", body)
        .then((res) => {
          this.$router.push("/" + this.$i18n.locale + "/user");
          this.$store.dispatch("Alert/activate", {
            type: "success",
            text: "Utilisateur ajouté avec succée",
          });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status && err.response.status === 409) {
            this.$store.dispatch("Alert/activate", {
              text: "Un utilisateur avec cette adresse email existe deja.",
              type: "danger",
            });
          } else {
            this.$router.push("/" + this.$i18n.locale + "/user");
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <iq-card>
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Ajouter un utilisateur</h4>
        </div>
      </div>
      <form @submit="handleSubmit" class="iq-card-body">
        <div class="form-group row">
          <div class="col-lg-6">
            <label>Nom</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nom"
              required
            />
            <b-form-checkbox
              v-model="isauditor"
              :value="1"
              :unchecked-value="0"
              class="mt-2"
            >
              Est auditeur externe
            </b-form-checkbox>
          </div>
          <div class="col-lg-6">
            <label>E-mail</label>
            <input
              type="email"
              class="form-control"
              placeholder="E-mail"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label>Mot de passe</label>
            <input
              type="password"
              class="form-control"
              placeholder="password"
              auocomplete="off"
              v-model="password"
              required
            />
          </div>
          <div class="col-lg-6">
            <label>Confirmation de mot de passe</label>
            <b-form-input
              type="password"
              placeholder="Confirmation"
              auocomplete="off"
              :state="confirm == password"
              v-model="confirm"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label>Etablissement</label>
            <select class="form-control" v-model="selectedInst" required>
              <option v-for="i of inst" :key="i.id" :value="i">
                {{ i.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-6">
            <label>Service</label>
            <select
              v-if="selectedInst && selectedInst.services.length"
              class="form-control"
              required
            >
              <option
                v-for="s of selectedInst.services"
                :key="s.id"
                :value="s.id"
              >
                {{ s.name }}
              </option>
            </select>
            <select
              class="form-control"
              required
              v-if="selectedInst && !selectedInst.services.length"
              disabled
            >
              <option>L'établissement ne contient aucun service.</option>
            </select>

            <select class="form-control" v-if="!selectedInst" disabled>
              <option>Selectionner un établissement.</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label>Fonctions</label>
            <MultiS
              :options="pos"
              :reduce="(pos) => pos.id"
              label="title"
              v-model="selectedPos"
              :class="'style-chooser'"
              multiple
            />
          </div>
          <div class="col-lg-6">
            <label>Rôles</label>
            <MultiS
              :options="roles"
              :reduce="(role) => role.id"
              v-model="selectedRoles"
              label="name"
              :class="
                selectedRoles.length
                  ? 'style-chooser'
                  : 'style-chooser required'
              "
              multiple
            />
            <span class="text-danger m-2" v-if="!selectedRoles.length"
              >Les roles sont requis</span
            >
          </div>
        </div>

        <button type="button" class="btn iq-bg-danger" @click="$router.back()">
          Annuler
        </button>
        <button type="submit" class="btn btn-primary mx-2">Enregistrer</button>
      </form>
    </iq-card>
  </b-container>
</template>

<style>
.style-chooser .vs__dropdown-toggle {
  height: 45px;
  border-radius: 10px;
}

.required .vs__dropdown-toggle,
.required .vs__dropdown-menu {
  border-color: red;
}
</style>
