<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">{{ tableTitle }}</h4>
          </template>
          <template v-slot:headerAction>
            <div class="row" style="padding: 10px">
              <b-form-input
                id="filter-input"
                class="col m-2"
                style="width: 300px; height: 35px"
                v-model="filter"
                type="search"
                :placeholder="$t('inputs.search')"
              ></b-form-input>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table
                  bordered
                  :filter="filter"
                  @filtered="onFiltered"
                  hover
                  :per-page="20"
                  :current-page="currentPage"
                  :items="rows"
                  id="my-table"
                  :fields="cols"
                  foot-clone
                >
                  <template v-slot:cell(guides)="data">
                    <li v-for="guid of data.item.survey.guides" :key="guid.id">
                      {{ guid.title }}
                    </li>
                  </template>
                  <template v-slot:cell(services)="data">
                    <li v-for="ser of data.item.survey.services" :key="ser.id">
                      {{ ser.name }}
                    </li>
                  </template>
                  <template v-slot:cell(progress)="data">
                    <div class="text-center">
                      <span> {{ data.item.progression_percent }} %</span>
                    </div>
                    <b-progress height="1.5rem">
                      <b-progress-bar
                        :value="data.item.progression_percent"
                        variant="success"
                      >
                      </b-progress-bar
                    ></b-progress>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      v-if="data.item.user.id == user.id"
                      variant=" iq-bg-danger mr-1 mt-1"
                      :title="$t('buttons.myResults')"
                      size="sm"
                      :to="
                        '/' +
                        $i18n.locale +
                        `/assessment/result/${data.item.id}`
                      "
                      ><i class="ri-bar-chart-fill m-0"></i>
                      {{ $t("buttons.myResults") }}</b-button
                    >
                    <b-button
                      v-else
                      variant=" iq-bg-primary mr-1 mt-1"
                      :title="$t('buttons.results')"
                      size="sm"
                      :to="
                        '/' +
                        $i18n.locale +
                        `/assessment/result/${data.item.id}`
                      "
                      ><i class="ri-bar-chart-fill m-0"></i>
                      {{ $t("buttons.results") }}
                    </b-button>
                    <!-- <b-button
                      variant=" iq-bg-primary mr-1 mt-1"
                      size="sm"
                      title="Demarrer le questionnaire"
                      v-if="data.item.user.id == user.id"
                      :to="'/'+$i18n.locale + 
                        `/assessment/start?survey_id=${data.item.survey.id}&assessment_id=${data.item.id}`
                      "
                      ><i class="ri-play-line m-0"></i
                    ></b-button> -->
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3 pull-right"
                  v-model="currentPage"
                  :total-rows="rows.length"
                  :per-page="20"
                  aria-controls="my-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import { xray } from "../../config/pluginInit";
import ApiService from "../../Utils/axios";

export default {
  name: "UiDataTable",
  props: {
    addBtn: Object,
    tableTitle: String,
    rows: Array,
    cols: Array,
    fetchList: Function,
  },
  mounted() {
    xray.index();
  },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  methods: {
    default() {
      return {
        id: this.rows.length,
      };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    edit(id) {
      this.$router.push("/" + this.$i18n.locale + "/survey/edit/" + id);
    },
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.title;
      this.$bvModal.show("modal-1");
    },
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/survey/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Questionnaire supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "Le questionnaire ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
  },
  data() {
    return {
      currentPage: 1,
      filter: "",
      modalMsg: "",
      item: null,
    };
  },
};
</script>

<style>
.star {
  width: 18px;
  color: #eeeeee;
}

.laurel {
  width: 27px;
}
</style>
